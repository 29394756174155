<template>
  <div>
    <v-navigation-drawer temporary v-model="drawer" absolute> <!--navigation drawer-->
      <v-list class="pa-1" v-if="currentUserInformation.data"><!--heading-->
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <img src="https://randomuser.me/api/portraits/men/85.jpg" >
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ currentUserInformation.data.first_name }} {{ currentUserInformation.data.last_name }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list><!--/heading-->
      <v-list class="pt-0" dense><!--navigation-->
        <v-divider></v-divider>
        <template v-for="item in navItems">
          <v-list-group v-if="item.childs" :key="item.name"><!--with childs-->
            <v-list-tile slot="activator">
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.name }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile v-for="(child, i) in item.childs" :key="i" @click="goto(child.url)">
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ child.name }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group><!--/with childs-->
          <v-list-tile v-else @click="goto(item.url)" :key="item.name"> <!--without childs-->
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile><!--/without childs-->
        </template>
        <!--here is not includes on nav.js-->
        <v-list-tile @click="openLoginDialog()" v-if="!currentUserInformation.data"> <!--without childs-->
          <v-list-tile-action>
            <v-icon>meeting_room</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Login</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile><!--/login-->
        <v-list-group v-if="currentUserInformation.data"><!--login/register-->
          <v-list-tile slot="activator">
            <v-list-tile-action>
              <v-icon>person</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                Account
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!-- <v-list-tile @click="goto('')">
            <v-list-tile-content>
              <v-list-tile-title>
                Profile
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile> -->
          <v-list-tile @click="goto('/transaction/history')">
            <v-list-tile-title>
              My Order(s)
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="goto('/wishlist/')">
            <v-list-tile-title>
              My Wishlist
              <span v-if="wishlist">({{ wishlist.products.length }})</span>
              <span v-else>(0)</span>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="logout">
            <v-list-tile-content>
              <v-list-tile-title>
                Logout
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group><!--/with childs-->
        <v-list-tile @click="goto('/cart/')" v-if="currentUserInformation.data"> <!--without childs-->
          <v-list-tile-action>
            <v-icon>shopping_cart</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Cart</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile><!--/cart-->
      </v-list><!--/navigation-->
    </v-navigation-drawer><!--/navigation drawer-->
    <v-toolbar class="white gd-nav"><!--toolbar-->
      <span class="hidden-md-and-up f-1">
        <v-toolbar-side-icon @click.stop="drawer = !drawer" />
      </span>
      <div class="mt-1 ml-2 f-1 text-center">
        <router-link to="/index">
          <img src="/static/img/logo-sd-175.png" alt="Kopi Ketjil" height="48">
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down overline-animation-2">
        <v-btn class="gdline text-helvetica"
               flat
               @click="goto('/franchise')">
          <v-icon dark>star</v-icon>
          Franchise Here
        </v-btn>
        <v-menu offset-y :key="index" v-for="(item, index) in navItems">
          <v-btn class="gdline text-helvetica"
                 flat
                 slot="activator"
                 @click="goto(item.url)">
            {{ item.name }}
            <v-icon v-if="item.childs">arrow_drop_down</v-icon>
          </v-btn>
          <v-list dark>
            <v-list-tile v-for="child in item.childs" :key="child.name" @click="goto(child.url)">
              <v-list-tile-title>{{ child.name }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <!--login/register-->
        <v-btn class="gdline text-helvetica"
               flat
               slot="activator"
               v-if="!currentUserInformation.data"
               @click.native.stop="openLoginDialog()">
            <v-list-tile-title>
                Login
            </v-list-tile-title>
        </v-btn>

        <!--profile-->
        <v-menu offset-y v-if="currentUserInformation.data">
          <v-btn class="gdline text-helvetica"
                 flat
                 slot="activator"
                 @click="goto('')">
            <v-icon>
              person
            </v-icon>
            <v-icon>arrow_drop_down</v-icon>
          </v-btn>
          <v-list dark>
            <!-- <v-list-tile @click="goto('')">
              <v-list-tile-title>
                Profile
              </v-list-tile-title>
            </v-list-tile> -->
            <v-list-tile @click="goto('/transaction/history')">
              <v-list-tile-title>
                My Order(s)
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="goto('/wishlist/')">
              <v-list-tile-title>
                My Wishlist
                <span v-if="wishlist">({{ wishlist.products.length }})</span>
                <span v-else>(0)</span>
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="logout">
              <v-list-tile-title>
                Logout
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <!--cart-->
        <v-btn class="gdline text-helvetica"
               flat
               slot="activator"
               v-if="currentUserInformation.data"
               @click.native.stop="goto('/cart/')">
          <v-badge right>
            <span slot="badge" v-if="cart">{{ cart.products.length }}</span>
            <v-icon>
              shopping_cart
            </v-icon>
          </v-badge>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar><!--/toolbar-->
    <!--Modal-->
    <Login></Login>
    <Loading :isVisible="$store.state.loading"></Loading>
  </div>
</template>

<script>
import Login from '@/forms/Login'
import { EventBus } from '@/event-bus.js'
import { mapGetters } from 'vuex'
import Loading from "@/components/Loading";

export default {
  name: 'app-header',
  computed: {
    ...mapGetters(['cart', 'user', 'currentUserInformation', 'wishlist'])
  },
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    Login,
    Loading
  },
  data () {
    return {
      drawer: null,
      title: 'Coffee Ketjil'
    }
  },
  beforeCreate () {
    this.$store.dispatch('getUser', {
      data: {},
      success: () => {},
      fail: () => {}
    })
  },
  created () {
    this.loadCartData()
    this.loadWishlistData()
  },
  methods: {
    loadCartData () {
      this.$store.dispatch('getCartData', {
        success: () => {},
        fail: () => {}
      })
    },
    loadWishlistData () {
      this.$store.dispatch('getWishlistData', {
        success: () => {},
        fail: () => {}
      })
    },
    goto (url) {
      if (url !== '') {
        this.$router.push(url)
      }
    },
    openLoginDialog () {
      EventBus.$emit('dialog', true)
    },
    logout () {
      this.$store.dispatch('logout', {
        data: {},
        success: () => {
          location.href = '/'
        },
        fail: () => {
          location.href = '/'
        }
      })
    }
  }
}
</script>
