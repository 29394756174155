<template>
  <v-layout row wrap>
    <v-flex xs12 md6>
      <div>
        <img :src="getPath(currentProduct.data.product_images[0].image_url)" :alt="currentProduct.data.id" class="gd-image-container">
      </div>
      <div>
        <img :src="getPath(product_image.image_url)" :alt="currentProduct.data.id" class="gd-image-container-fit ma-2 pointer pa-2" :key="product_image.image_url" width="80" v-for="product_image in currentProduct.data.product_images">
      </div>
    </v-flex>
    <v-flex xs12 md6>
      <h3 class="text-executive-lg-black uppercase">{{ currentProduct.data.name }}</h3>
      <div class="gd-favorite-container">
        <!--<v-icon class="gd-favorite-large pointer" v-if="!product.favorite" @click="product.favorite = !product.favorite">favorite_border</v-icon>-->
        <!--<v-icon class="gd-favorite-large pointer" v-else @click="product.favorite = !product.favorite">favorite</v-icon>-->
        <!-- <v-btn class="dark ml-3" dark large @click="addToCart(currentProduct)">Add to Cart</v-btn> -->
      </div>
      <div class="mt-5">
        <p class="text-courier-new-md-black uppercase bold">Product Detail</p>
        <hr>
        <p class="text-courier-new-xs">{{ currentProduct.data.description }}</p>
      </div>
      <div class="mt-5" v-if="currentProduct.category_parent == 115">
        <!-- <v-select
          :items="this.param.servingType"
          v-model="selectedServingType"
          :v-validate="'required'"
          label="Serving Type"
        ></v-select>
        <v-select
          :items="this.param.roastedType"
          v-model="selectedRoastedType"
          :v-validate="'required'"
          label="Roasted Type"
        ></v-select>
        <v-select
          :items="this.param.mill"
          v-model="selectedMill"
          :v-validate="'required'"
          label="Grind Size"
        ></v-select> -->
        <v-select
          :items="this.masukinServingType"
          v-model="selectedNewServingType"
          v-on:change="changeServingType"
          label="Serving Type"
        ></v-select>
        <v-select v-if="selectedNewServingType !== 'Green Beans'"
          :items="this.masukinRoastedType"
          v-model="selectedNewRoastedType"
          v-on:change="changeRoastedType"
          label="Roast Type"
        ></v-select>
        <v-select v-else
          :items="this.masukinRoastedType"
          v-model="selectedNewRoastedType"
          v-on:change="changeRoastedType"
          disabled="true"
          label="Roast Type"
        ></v-select>
        <v-select v-if="selectedNewServingType !== 'Whole Roasted Beans'"
          :items="this.masukinGrindSize"
          v-model="selectedNewGrindSize"
          v-on:change="changeGrindSize"
          label="Grind Size"
        ></v-select>
        <v-select v-else
          :items="this.masukinGrindSize"
          v-model="this.selectedNewGrindSize"
          v-on:change="changeGrindSize"
          disabled="true"
          label="Grind Size"
        ></v-select>
        <v-select v-if="this.regionStorage === ''"
          :items="this.regionItems"
          v-model="selectedRegion"
          v-on:change="changeRegion"
          label="Region"
        ></v-select>
        <v-select v-else
          :items="this.regionItems"
          v-model="selectedRegion"
          v-on:change="changeRegion"
          disabled="true"
          label="Region">
        </v-select>
      </div>
      <div v-if="currentProduct.category_parent != 115">
        <v-select v-if="this.regionStorage === ''"
          :items="this.regionItems"
          v-model="selectedRegion"
          v-on:change="changeRegion"
          label="Region"
        ></v-select>
        <v-select v-else
          :items="this.regionItems"
          v-model="selectedRegion"
          disabled="true"
          label="Region">
        </v-select>
        <p class="text-courier-new-md-black bold" v-if="this.region=='Indonesia'">Rp. {{ currentProduct.data.indonesia_price + this.totalAddOnPrice}}</p>
        <p class="text-courier-new-md-black bold" v-if="this.region=='United Arab Emirates'">AED {{ currentProduct.data.aed_price + this.totalAddOnPrice}}</p>
        <p class="text-courier-new-md-black bold" v-if="this.region=='Others'">${{ currentProduct.data.usd_price + this.totalAddOnPrice}}</p>
      </div>
      <div v-else-if="currentProduct.category_parent == 115">
        <p class="text-courier-new-md-black bold" id="holder-harga"><span id="harga-product"></span></p>
      </div>
      <div class="mt-5">
        <!-- <p class="text-courier-new-md-black uppercase bold">Shipping</p>
        <hr> -->
        <v-btn class="dark ml-3" dark large @click="addToCart(currentProduct)">Add to Cart</v-btn>
      </div>
    </v-flex>
    <v-flex xs12>
      <div class="gd-separator-big">
        <p class="text-courier-new-md-white bold uppercase">Similar Products</p>
      </div>
      <v-container>
        <v-layout row wrap align-center>
          <v-flex
            xs12
            md3
            v-for="product in recentProducts.data"
            :key="product.id"
            @click="viewDetail(product.id)"
            class="pointer">
            <v-card>
              <v-card-media class="tr-image-product" :src="getPath(product.product_images[0].image_url)" height="250">
              </v-card-media>
              <v-card-title primary-title class="remove-padding">
                <v-flex class="text-md-center remove-padding">
                  <h4 class="text-courier-new-xs-black-bold uppercase">{{ product.name }}</h4>
                  <!-- <div class="text-courier-new-xs uppercase bold">${{ product.price }}</div> -->
                </v-flex>
              </v-card-title>
            </v-card>
          </v-flex><!--/product-list-->
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus.js'
import config from "../config";

export default {
  data () {
    return {
      param: {
        servingType: ['Ground', 'Roasted Beans', 'Green Bean'],
        roastedType: ['Light Roast', 'Medium Roast', 'Dark or Full Roast', 'Espresso', 'Not Roasted'],
        mill: ['Fine', 'Medium', 'Hard', 'Whole Beans']
      },
      selectedServingType: ['Ground Roasted Beans', 'Whole Roasted Beans', 'Green Beans'],
      selectedRoastedType: ['Arabic', 'Filter', 'Espresso'],
      selectedMill: ['Fine', 'Medium', 'Coarse', 'Whole Beans'],
      masukinServingType: [],
      selectedNewServingType: [],
      masukinArabPriceServingType: [],
      masukinIndonesiaPriceServingType: [],
      masukinUsdPriceServingType: [],
      cekValueServing: '',
      masukinRoastedType: [],
      selectedNewRoastedType: [],
      masukinArabPriceRoastedType: [],
      masukinIndonesiaPriceRoastedType: [],
      masukinUsdPriceRoastedType: [],
      cekValueRoasted: '',
      masukinGrindSize: [],
      panjangGrindSize: 0,
      selectedNewGrindSize: [],
      masukinArabPriceGrindSize: [],
      masukinIndonesiaPriceGrindSize: [],
      masukinUsdPriceGrindSize: [],
      cekValueGrind: '',
      totalAddOnPrice: 0,
      productPrice: 0,
      region: '',
      regionStorage: '',
      regionItems: ['Indonesia', 'United Arab Emirates', 'Others'],
      selectedRegion: []
    }
  },
  created: function () {
    this.prepareData()
    document.getElementById('harga-product').outerHTML = ''
  },
  computed: {
    ...mapGetters(['recentProducts', 'cart', 'currentProduct'])
  },
  watch: {
    region (newRegion) {
      /* if (localStorage.region == null) {
        localStorage.region = newRegion
        this.region = newRegion
      } */
    }
  },
  methods: {
    prepareData: function () {
      this.param.id = this.$route.params.productId
      this.getProductDetail()
      this.getSimiliarProduct()
      this.getServingType()
      this.getRoastedType()
      this.getGrindSize()
      if (localStorage.region === 'null' || localStorage.region === '' || localStorage.region === null || localStorage.region === undefined || localStorage.region === 'undefined') {
        this.regionStorage = ''
        this.region = ''
      } else {
        this.regionStorage = localStorage.region
        this.region = this.regionStorage
        this.selectedRegion = this.region
      }
      console.log(localStorage.region)
    },
    changeRegion (val) {
      // localStorage.region = val
      // alert('changed!')
      this.region = val
      this.checkAllAddOn(this.cekValueServing, this.cekValueRoasted, this.cekValueGrind)
    },
    testing () {
      this.productPrice = parseInt(this.currentProduct.data.price)
      return ''
    },
    testingSimbol (val) {
      val = ''
    },
    changeServingType (val) {
      if (val != null) this.cekValueServing = val
      console.log(this.cekValueServing)
      if (this.cekValueServing === 'Whole Roasted Beans') {
        this.cekValueGrind = '-'
        this.selectedNewGrindSize = '-'
      } else if (this.cekValueServing === 'Green Beans') {
        this.cekValueRoasted = '-'
        this.selectedNewRoastedType = '-'
      }
      this.checkAllAddOn(this.cekValueServing, this.cekValueRoasted, this.cekValueGrind)
      // alert(this.productPrice)
    },
    changeRoastedType (val) {
      if (val != null) this.cekValueRoasted = val
      this.checkAllAddOn(this.cekValueServing, this.cekValueRoasted, this.cekValueGrind)
    },
    changeGrindSize (val) {
      if (val != null) this.cekValueGrind = val
      this.checkAllAddOn(this.cekValueServing, this.cekValueRoasted, this.cekValueGrind)
    },
    checkAllAddOn (cekValueServing, cekValueRoasted, cekValueGrind) {
      // console.log('asdasd')

      // console.log(this.region)
      let hargaProduct = document.getElementById('harga-product')
      if (cekValueServing !== '' && (cekValueRoasted !== '' || cekValueRoasted === '-') && (cekValueGrind !== '' || cekValueGrind === '-') && this.region !== '') {
        // console.log(this.region)
        let idx = 0
        let priceServing = 0
        let priceRoasted = 0
        let priceGrind = 0
        for (let i = 0; i < this.masukinServingType.length; i++) {
          if (cekValueServing === this.masukinServingType[i]) {
            idx = i
          }
        }

        // cek harga serving per region
        if (this.region === 'Indonesia') {
          priceServing = this.masukinIndonesiaPriceServingType[idx]
        } else if (this.region === 'United Arab Emirates') {
          priceServing = this.masukinArabPriceServingType[idx]
        } else if (this.region === 'Others') {
          priceServing = this.masukinUsdPriceServingType[idx]
        }
        console.log('harga serving: ' + this.selectedNewServingType)
        idx = 0
        for (let i = 0; i < this.masukinRoastedType.length; i++) {
          if (this.selectedNewRoastedType === this.masukinRoastedType[i]) {
            idx = i
          }
        }

        // cek harga roasted per region
        if (this.region === 'Indonesia') {
          priceRoasted = this.masukinIndonesiaPriceRoastedType[idx]
        } else if (this.region === 'United Arab Emirates') {
          priceRoasted = this.masukinArabPriceRoastedType[idx]
        } else if (this.region === 'Others') {
          priceRoasted = this.masukinUsdPriceRoastedType[idx]
        }
        idx = 0
        for (let i = 0; i < this.masukinGrindSize.length; i++) {
          if (this.selectedNewGrindSize === this.masukinGrindSize[i]) {
            idx = i
          }
        }
        let symbol = ''
        let productPrice = 0

        // cek harga grind per region
        if (this.region === 'Indonesia') {
          priceGrind = this.masukinIndonesiaPriceGrindSize[idx]
          symbol = 'Rp. '
          productPrice = parseInt(this.currentProduct.data.indonesia_price)
        } else if (this.region === 'United Arab Emirates') {
          priceGrind = this.masukinArabPriceGrindSize[idx]
          symbol = 'AED '
          productPrice = parseInt(this.currentProduct.data.aed_price)
        } else if (this.region === 'Others') {
          priceGrind = this.masukinUsdPriceGrindSize[idx]
          symbol = '$'
          productPrice = parseInt(this.currentProduct.data.usd_price)
        }
        // validasi kalo dia ga pilih grind
        if (cekValueGrind === '-') priceGrind = 0
        if (cekValueServing === '-') priceServing = 0
        if (cekValueRoasted === '-') priceRoasted = 0

        console.log('serving price: ' + priceServing)
        console.log('roasted price: ' + priceRoasted)
        console.log('grind price: ' + priceGrind)
        this.totalAddOnPrice = priceServing + priceRoasted + priceGrind
        let newHarga = parseInt(productPrice) + parseInt(this.totalAddOnPrice)
        hargaProduct.innerHTML = symbol + newHarga
      } else {
        // alert
      }
    },
    clearData () {
      this.masukinServingType = []
      this.selectedNewServingType = []
      this.masukinPriceServingType = []
      this.masukinRoastedType = []
      this.selectedNewRoastedType = []
      this.masukinPriceRoastedType = []
      this.masukinGrindSize = []
      this.selectedNewGrindSize = []
      this.masukinPriceGrindSize = []
      this.cekValueRoasted = ''
      this.cekValueServing = ''
      this.cekValueGrind = ''
      this.totalAddOnPrice = 0
      this.productPrice = 0
    },
    loadCartData () {

      this.$store.dispatch('getCartData', {
        success: () => {
          this.$router.push("/cart/detail");
        },
        fail: () => {}
      })
    },
    getProductDetail () {
      this.$store.dispatch('getCurrentProduct', {
        data: Object.assign({}, this.param),
        success: () => {},
        fail: () => {}
      })
    },
    getSimiliarProduct () {
      this.$store.dispatch('getRecentProducts', {
        data: Object.assign({}, this.param),
        success: () => {},
        fail: () => {}
      })
    },
    getPath (fileName) {
      return config.storage_base_path + fileName
    },
    getServingType () {
      this.$store.dispatch('getServingType', {
        data: Object.assign({}, this.param),
        success: (res) => {
          console.log('serving')
          res.data.data.forEach(servingTypeData => {
            this.masukinServingType.push(servingTypeData.item)
            this.selectedNewServingType.push(servingTypeData.item)
            this.masukinArabPriceServingType.push(servingTypeData.aed_price)
            this.masukinIndonesiaPriceServingType.push(servingTypeData.indonesia_price)
            this.masukinUsdPriceServingType.push(servingTypeData.usd_price)
          })
        },
        fail: () => {}
      })
    },
    getRoastedType () {
      this.$store.dispatch('getRoastedType', {
        data: Object.assign({}, this.param),
        success: (res) => {
          console.log('roasted')
          res.data.data.forEach(roastedTypeData => {
            this.masukinRoastedType.push(roastedTypeData.item)
            this.selectedNewRoastedType.push(roastedTypeData.item)
            this.masukinArabPriceRoastedType.push(roastedTypeData.aed_price)
            this.masukinIndonesiaPriceRoastedType.push(roastedTypeData.aed_price)
            this.masukinUsdPriceRoastedType.push(roastedTypeData.usd_price)
          })
        },
        fail: () => {}
      })
    },
    getGrindSize () {
      this.$store.dispatch('getGrindSize', {
        data: Object.assign({}, this.param),
        success: (res) => {
          console.log('grind')
          res.data.data.forEach(grindSizeData => {
            this.masukinGrindSize.push(grindSizeData.item)
            this.selectedNewGrindSize.push(grindSizeData.item)
            this.masukinArabPriceGrindSize.push(grindSizeData.aed_price)
            this.masukinIndonesiaPriceGrindSize.push(grindSizeData.indonesia_price)
            this.masukinUsdPriceGrindSize.push(grindSizeData.usd_price)
            this.panjangGrindSize += 1
          })
        },
        fail: () => {}
      })
    },
    addToCart (product) {
      let param = {
        id: product.data.id,
        quantity: 1,
        // todo: discount still 0
        discount: 0
      }
      if (product.category_parent !== 115) {
        param.notes = ''
        param.addonprice = 0
        param.region = this.region
      } else {
        if (this.selectedNewServingType !== 'Ground Roasted Beans' && this.selectedNewServingType !== 'Whole Roasted Beans' && this.selectedNewServingType !== 'Green Beans') {
          this.$toast('Choose Serving Type!')
          return
        }
        if ((this.selectedNewRoastedType !== 'Arabic' && this.selectedNewRoastedType !== 'Filter' && this.selectedNewRoastedType !== 'Espresso') && this.selectedNewRoastedType !== '-') {
          this.$toast('Choose Roasted Type!')
          return
        }
        if (this.selectedNewGrindSize !== 'Fine' && this.selectedNewGrindSize !== 'Medium' && this.selectedNewGrindSize !== 'Coarse' && this.selectedNewGrindSize !== 'Whole Beans' && this.selectedNewGrindSize !== '-') {
          this.$toast('Choose Mill Type!')
          return
        }
        if (this.region === '') {
          this.$toast('Choose Region!')
          return
        }
        param.notes = 'Serving Type: ' + this.selectedNewServingType + ' | Roasted Type: ' + this.selectedNewRoastedType + ' | Grind Size: ' + this.selectedNewGrindSize
        param.addonprice = this.totalAddOnPrice
        param.region = this.region
        localStorage.region = this.region
        // console.log(priceServing + ' ' + priceRoasted + ' ' + priceGrind)
      }
      this.$store.dispatch('addToCart', {
        data: Object.assign({}, param),
        success: () => {
          this.$toast('Product has been added to cart!')
          localStorage.region = this.region
          this.loadCartData()
        },
        fail: () => {
          EventBus.$emit('dialog', true)
        }
      })
    },
    viewDetail (id) {
      this.param.id = id
      this.clearData()
      this.getProductDetail()
      this.getSimiliarProduct()
      this.getServingType()
      this.getRoastedType()
      this.getGrindSize()
      document.getElementById('harga-product').innerHTML = ''
      this.$router.push('/shops/detail/' + id)
    }
  }
}
</script>
