<template>
  <v-flex xs12 class="soft-gray-background px-5 gd-size-modified mb-5">
    <v-container>
      <v-layout row wrap align-center>
        <v-flex xs12 md3>
          <img src="/static/img/smart-phone-183.png" alt="Smart Phone" class="margin-top-minus-5">
        </v-flex>
        <v-flex xs12 md6>
          <v-container grid-list-md>
            <v-layout row wrap align-center>
              <v-flex xs12 md3>
                <img src="/static/img/logo-circle-96.png" alt="Kopi Ketjil Circle">
              </v-flex>
              <v-flex xs12 md9>
                <h2 class="text-executive-md uppercase">
                  Kopi Ketjil In Your Hand!
                </h2>
                <h2 class="text-courier-new-xs">
                  Download Kopi Ketjil app now for exclusive
                  goodies, plus a free brewed coffee or hot
                  tea to welcome you into the fold.
                </h2>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
        <v-flex xs12 md3>
          <div class="my-3">
            <div><b>Coming Soon!</b></div>
            <a href="">
              <img src="/static/img/google-play-badge.png" alt="Google Play" height="40">
            </a>
          </div>
          <div class="my-3">
            <div><b>Coming Soon!</b></div>
            <a href="">
              <img src="/static/img/app-store-badge.png" alt="App Store" height="40">
            </a>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
export default {
  name: 'footer-apps'
}
</script>
