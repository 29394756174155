import axios from 'axios'
import config from '@/config'
import store from "../store";

// Goldwin's Note
// Set the key we'll use in local storage.
// Go to Chrome dev tools, application tab, click "Local Storage" and "http://localhost:8080"
// and you'll see this key set below (if logged in):
export const STORAGE_KEY = 'kopiketjil_external'

// const headers = {
//   'Content-Type': 'application/json',
//   'Authorization': `Bearer ${store.getters.auth.user.api_token}`
// }

/**
 * Used for send param through HTTP GET Method with Authorization Headers
 * @type {{headers: {Authorization: string}, params: {}}}
 */
// Sync with local storage.
let axiosParam = {
  headers: {},
  params: {}
}
if (localStorage.getItem(STORAGE_KEY)) {
  // console.log('Syncing Data')
  let syncedData = JSON.parse(localStorage.getItem(STORAGE_KEY))
  axiosParam.headers = {
    Authorization: `Bearer ${syncedData.data.api_key}`,
    'Access-Control-Allow-Headers': '*'
    // 'Access-Control-Allow-Origin': '*'
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
  }
  // console.log(axiosParam)
}

axios.interceptors.request.use((config) => {
  store.commit("setLoading", true);
  return config;
});

axios.interceptors.response.use((config) => {
  store.commit("setLoading", false);
  return config;
});

export default {
  removeWishlistProduct (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.post(config.api.kopi_ketjil.wishlists + '/delete/' + id, data, axiosParam)
      .then(callback)
      .catch(fail)
  },
  getWishlistData (callback, fail) {
    axios.get(config.api.kopi_ketjil.wishlists + '/get', axiosParam)
      .then(callback)
      .catch(fail)
  },
  addToWishlist (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.post(config.api.kopi_ketjil.wishlists + '/addProduct/' + id, data, axiosParam)
      .then(callback)
      .catch(fail)
  },
  getTransactions (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.transactions + '/history', axiosParam)
      .then(callback)
      .catch(fail)
  },
  checkoutTransaction (callback, data, fail) {
    axios.post(config.api.kopi_ketjil.transactions + '/checkout', data, axiosParam)
      .then(callback)
      .catch(fail)
  },
  removeCartProduct (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.post(config.api.kopi_ketjil.carts + '/delete/' + id, data, axiosParam)
      .then(callback)
      .catch(fail)
  },
  getCartData (callback, fail) {
    axios.get(config.api.kopi_ketjil.carts + '/get', axiosParam)
      .then(callback)
      .catch(fail)
  },
  addToCart (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.post(config.api.kopi_ketjil.carts + '/addProduct/' + id, data, axiosParam)
      .then(callback)
      .catch(fail)
  },
  updateCartProductQuantity (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.post(config.api.kopi_ketjil.carts + '/product/edit-qty/' + id, data, axiosParam)
      .then(callback)
      .catch(fail)
  },
  getUser (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.user, axiosParam)
      .then(callback)
      .catch(fail)
  },
  login (callback, data, fail) {
    axios.post(config.api.kopi_ketjil.login, data)
      .then(callback)
      .catch(fail)
  },
  register (callback, data, fail) {
    axios.post(config.api.kopi_ketjil.register, data)
      .then(callback)
      .catch(fail)
  },
  logout (callback, data, fail) {
    localStorage.removeItem(STORAGE_KEY)
    axios.get(config.api.kopi_ketjil.logout, {params: data})
      .then(callback)
      .catch(fail)
  },
  getCurrentPost (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.get(config.api.kopi_ketjil.posts + '/getPost/' + id, {params: data})
      .then(callback)
      .catch(fail)
  },
  getProductSpecific (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.get(config.api.kopi_ketjil.products + '/get/' + id, {params: data})
      .then(callback)
      .catch(fail)
  },
  getProducts (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.products, {params: data})
      .then(callback)
      .catch(fail)
  },
  getGalleries (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.galleries + '/all', {params: data})
      .then(callback)
      .catch(fail)
  },
  getVideos (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.videos + '/all', {params: data})
      .then(callback)
      .catch(fail)
  },
  getNewsFeed (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.posts + '/getNews', {params: data})
      .then(callback)
      .catch(fail)
  },
  getRecentPosts (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.posts + '/recentPosts', {params: data})
      .then(callback)
      .catch(fail)
  },
  getRecentNews (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.posts + '/recentNews', {params: data})
      .then(callback)
      .catch(fail)
  },
  getRecentProducts (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.products + '/recent', {params: data})
      .then(callback)
      .catch(fail)
  },
  getCategories (callback, data, fail) {
    axios.get(config.api.kopi_ketjil.categories + '/all', {params: data})
      .then(callback)
      .catch(fail)
  },
  submitFranchise (callback, data, fail) {
    axios.post(config.api.kopi_ketjil.franchise + '/submit', data)
      .then(callback)
      .catch(fail)
  },
  getServingType (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.get(config.api.kopi_ketjil.product_category + '/get/serving-type/' + id, {params: data})
      .then(callback)
      .catch(fail)
  },
  getRoastedType (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.get(config.api.kopi_ketjil.product_category + '/get/roasted-type/' + id, {params: data})
      .then(callback)
      .catch(fail)
  },
  getGrindSize (callback, data, fail) {
    let id = encodeURI(data.id)
    axios.get(config.api.kopi_ketjil.product_category + '/get/grind-size/' + id, {params: data})
      .then(callback)
      .catch(fail)
  },
  getCalculateShipping (callback, data, fail) {
    axios.post(config.api.kopi_ketjil.calculate_shipping_price, data)
        .then(callback)
        .catch(fail)
  },
  createToken (callback, data, fail) {
    axios.post("https://api.xendit.co/v2/credit_card_tokens", data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + btoa("xnd_public_development_I1DpJx82P5sUaFvFVMCWU3nGEOp3q5GweMaFij5TkGRcOlazfXl4RXM81gyc:")
      }
    })
        .then(callback)
        .catch(fail)
  },
}
