import Vue from 'vue'
import Router from 'vue-router'

// Containers
import Full from '@/containers/Full'

// Views
import Index from '@/views/Index'
// Shops
import Shop from '@/views/Shop'
import ListProduct from '@/views/ListProduct'
import SingleProduct from '@/views/SingleProduct'
// About Us
import AboutUs from '@/views/AboutUs'
// Gallery
import Gallery from '@/views/Gallery'
import GalleryMenu from '@/views/GalleryMenu'
import GalleryVideo from '@/views/GalleryVideo'
// News
import News from '@/views/News'
import NewsHall from '@/views/NewsHall'
import NewsPromotion from '@/views/NewsPromotion'
import NewsFeed from '@/views/NewsFeed'
import SingleNews from '@/views/SingleNews'
// Franchise
import Franchise from '@/views/Franchise'
// Apps
import Apps from '@/views/Apps'
// Cart
import Cart from '@/views/Cart'
import CartDetail from '@/views/CartDetail'
import CartCheckout from '@/views/CartCheckout'
import CartCheckoutFirstStep from '@/views/CartCheckoutFirstStep'
import CartCheckoutSecondStep from '@/views/CartCheckoutSecondStep'
// Wishlist
import Wishlist from '@/views/Wishlist'
import WishlistDetail from '@/views/WishlistDetail'
// Transaction
import TransactionHistory from '@/views/TransactionHistory'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/index',
      name: 'Home',
      component: Full,
      children: [
        {
          path: 'index',
          name: 'Index',
          component: Index
        },
        {
          path: 'shops',
          redirect: 'shops',
          name: 'Shop',
          component: Shop,
          children: [
            {
              path: '',
              name: 'ListProduct',
              component: ListProduct
            },
            {
              path: 'detail/:productId',
              props: true,
              name: 'SingleProduct',
              component: SingleProduct
            }
          ]
        },
        {
          // TODO: make view-router for about-us and gallery, for each children its has
          path: 'about-us/background',
          name: 'Background',
          component: AboutUs
        },
        {
          path: 'gallery',
          redirect: '/gallery/menu',
          name: 'Gallery',
          component: Gallery,
          children: [
            {
              path: 'menu',
              name: 'GalleryMenu',
              component: GalleryMenu
            },
            {
              path: 'video',
              name: 'GalleryVideo',
              component: GalleryVideo
            }
          ]
        },
        {
          path: 'news',
          redirect: '/news/hall',
          name: 'News',
          component: News,
          children: [
            {
              path: 'hall',
              name: 'NewsHall',
              component: NewsHall
            },
            {
              path: 'promotion',
              name: 'NewsPromotion',
              component: NewsPromotion
            },
            {
              path: 'feed',
              name: 'NewsFeed',
              component: NewsFeed
            },
            {
              path: 'feed/:newsFeedId',
              props: true,
              name: 'SingleNews',
              component: SingleNews
            }
          ]
        },
        {
          path: 'franchise',
          name: 'Franchise',
          component: Franchise
        },
        {
          path: 'apps',
          name: 'Apps',
          component: Apps
        },
        {
          path: 'cart',
          redirect: 'cart/detail',
          name: 'Cart',
          component: Cart,
          children: [
            {
              path: 'detail',
              name: 'CartDetail',
              component: CartDetail
            },
            {
              path: 'checkout',
              redirect: 'checkout/shipment',
              name: 'CartCheckout',
              component: CartCheckout,
              children: [
                {
                  path: 'shipment',
                  name: 'CartCheckoutFirstStep',
                  component: CartCheckoutFirstStep
                },
                {
                  path: 'review',
                  name: 'CartCheckoutSecondStep',
                  component: CartCheckoutSecondStep
                }
              ]
            }
          ]
        },
        {
          path: 'wishlist',
          redirect: 'wishlist/detail',
          name: 'Wishlist',
          component: Wishlist,
          children: [
            {
              path: 'detail',
              name: 'WishlistDetail',
              component: WishlistDetail
            }
          ]
        },
        {
          path: 'transaction/history',
          name: 'Transaction History',
          component: TransactionHistory
        }
      ]
    }
  ],
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
})
