<template>
  <v-content class="gd-background">
    <router-view class="mb-5"></router-view>
    <FooterApps />
  </v-content>
</template>

<script>
import FooterApps from '@/components/FooterApps'

export default {
  name: 'gallery',
  components: {
    FooterApps
  }
}
</script>
