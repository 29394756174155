<template>
  <div>
    <section><!--top-->
      <div style="background-image: url(/static/img/slider-1.png); background-size: cover;" class="py-4">
        <v-layout column align-end justify-center class="white--text mr-5">
          <v-flex xs12 md6 offset-md6 class="pl-5">
            <div class="white--text mb-3 text-md-left">
              <h1 class="text-executive-lg">INDONESIAN</h1>
              <h1 class="text-executive-lg">SPECIALTY COFFEE</h1>
              <h5 class="text-morsal-xs">From Indonesia to Abu Dhabi with Love</h5>
              <p class="mt-3 text-courier-new-xs-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis augue tellus,
                nec rutrum ante tincidunt rhoncus. Curabitur viverra convallis ligula ac rutrum. Morbi ipsum lectus,
                placerat sit amet nisi a, ullamcorper tempor diam. Nunc tristique luctus nisl at gravida.
                Nulla cursus congue ante a sagittis. Quisque malesuada ante mi, sit amet imperdiet metus ornare non.
                Etiam nisi sem, ullamcorper et semper eu, porta et ex. Phasellus egestas diam nec enim vehicula rhoncus.
                Cras commodo leo non odio venenatis, laoreet ullamcorper.
              </p>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </section><!--/top-->

    <section><!--list-product-->
      <v-container grid-list-xl>
        <v-layout row wrap align-center>
          <v-flex xs12 md12>
            <div class="text-md-center text-xs-center text-helvetica-lg uppercase">
              <h2>OUR SHOP SALE</h2>
            </div>
          </v-flex>
          <v-flex
            xs12
            md3
            v-for="product in recentProducts.data"
            :key="product.id"
            @click="viewDetail(product.id)"
            class="pointer">
            <v-card flat>
              <v-card-title primary-title class="remove-padding">
                <v-flex class="text-md-center text-xs-center remove-padding">
                  <img class="tr-image-product" :src="getPath(product.product_images[0].image_url)" alt="">
                  <h4 class="text-courier-new-xs-black-bold uppercase">{{ product.name }}</h4>
                  <!-- <div class="text-courier-new-xs uppercase bold">IDR {{ product.price }}</div> -->
                  <v-btn dark @click="viewDetail(product.id)">Go to Shop</v-btn>
                </v-flex>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section><!--/list-product-->

    <section><!--blue-content-->
      <v-parallax src="/static/img/background-blue-flat.png" height="auto">
        <v-container grid-list-xl class="white--text text-lg-center">
          <v-layout row wrap justify-center>
            <v-flex xs12 md4>
              <img src="/static/img/calendar.png" style="width: 100%" alt="Kopi Ketjil - Calendar">
            </v-flex>
            <v-flex xs12 md8 pl-5>
              <h1 class="text-md-left underline bold text-executive-lg mb-5">
                WE TREAT OUR <br>
                FRIENDS DEARLY
              </h1>
              <p class="text-md-center text-executive-xs gd-padding-fit mb-5">
                Every purchase is a 20% off.
                Get 1 stamp by spending minimial 25AED. After 10 stamps, you’ll get 15AED off of your next purchase.
              </p>
              <p class="text-md-left text-courier-new-xxs white--text gd-padding-xfit">
                T&C:
                - 25AED spend with no tax included and
                has been reduced by 20% discount
                - This loyalty card has no cash value
                - Not valid with other discount & promotion
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-parallax>
    </section><!--/blue-content-->

    <section><!--delivery-content-->
      <v-parallax src="/static/img/background-delivery-free.png" height="600">
        <v-container grid-list-xl class="white--text text-lg-center">
          <v-layout wrap class="justify-end align-end" style="height: 100%;">
            <v-flex xs12 md9 ml-5 pl-5 offset-md3 class="gd-margin-fit">
              <p class="text-md-right text-courier-new-md-black gd-padding-xfit">
                Just give us a call at <br>
                <b>+971(0)503224068</b> and
                we deliver right away!
                <br>
                <small>T&C: Minimum order 45 AED</small>
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-parallax>
    </section><!--/delivery-content-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from "../config";

export default {
  name: 'news-hall',
  created () {
    this.getRecentProducts()
  },
  computed: {
    ...mapGetters(['recentProducts'])
  },
  methods: {
    getRecentProducts () {
      this.$store.dispatch('getRecentProducts', {
        success: () => {},
        fail: () => {}
      })
    },
    viewDetail (id) {
      this.$router.push('/shops/detail/' + id)
    },
    getPath (fileName) {
      if (fileName === undefined) {
        return '/static/img/products/default-140.png'
      }
      return config.storage_base_path + fileName
    },
    share (product, shareable) {
      if (shareable.name === 'link') {
        let copyTarget = document.getElementById(product.id + '-' + product.name)
        copyTarget.select()
        document.execCommand('Copy')
      } else {
        // TODO: Push to share
      }
    }
  }
}
</script>
