<template>
  <v-layout row wrap>
    <v-flex
      xs12
      md3
      v-for="product in products.data"
      :key="product.id"
      class="pointer">
      <v-card flat>
        <v-card-title>
          <img class="tr-image-product" :src="getPath(product.product_images[0].image_url)" :alt="product.name" @click="viewDetail(product.id)">
          <div class="gd-favorite tooltip pull-right absolute" @click="addToWishlist(product)">
            <v-icon v-if="!inWishlist(product)">favorite_border</v-icon>
            <v-icon v-else>favorite</v-icon>
            <span class="tooltiptext text-courier-new-xxxs">
                              Click to add to wish list <br>
                              <hr class="my-1">
                              <i :class="shareable.icon"
                                 aria-hidden="true"
                                 :key="shareable.name"
                                 v-for="shareable in shareables"
                                 class="mx-1"
                                 @click.stop="share(product, shareable)"></i>
              <!--TODO: Change value product name to product url-->
                              <input type="hidden" :value="product.name" :id="product.id + '-' + product.name">
                            </span>
          </div>
          <v-flex class="text-md-center remove-padding" @click="viewDetail(product.id)">
            <h4 class="text-courier-new-xs-black-bold uppercase">{{ product.name }}</h4>
            <!-- <div class="text-courier-new-xs uppercase bold">${{ product.price }}</div> -->
          </v-flex>
        </v-card-title>
      </v-card>
    </v-flex><!--/product-list-->
    <v-flex xs12 justify-center align-center class="text-md-center my-3">
      <v-pagination :length="products.last_page"
                    v-model="products.current_page"
                    @input="loadPage"></v-pagination>
    </v-flex>
  </v-layout>
</template>

<script>
import updateQuery from 'vue-update-query-mixin'
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus.js'
import config from "../config";

export default {
  name: 'list-product',
  data () {
    return {
      shareables: [
        {name: 'facebook', icon: 'fa fa-facebook-square'},
        {name: 'google plus', icon: 'fa fa-google-plus'},
        {name: 'whatsapp', icon: 'fa fa-whatsapp'},
        {name: 'link', icon: 'fa fa-link'}
      ],
      param: {},
      isLoading: false
    }
  },
  created () {
    this.prepareData()
    this.searchProducts()
    this.loadWishlistData()
  },
  computed: {
    ...mapGetters(['products', 'wishlist'])
  },
  mixins: [updateQuery],
  methods: {
    prepareData () {
      this.param = {
        // username: this.$route.query.username || null,
        // logisticProviderCode: this.$route.query.logisticProviderCode || null,
        // sortBy: this.$route.query.sortBy || null,
        // sortDirection: this.$route.query.sortDirection || null
        page: this.$route.query.page || 1
      }
    },
    inWishlist (product) {
      if (this.wishlist) {
        for (let find of this.wishlist.products) {
          if (product.id === find.id) return true
        }
      }
      return false
    },
    addToWishlist (product) {
      if (!this.inWishlist(product)) {
        this.$store.dispatch('addToWishlist', {
          data: Object.assign({}, product),
          success: () => {
            this.loadWishlistData()
          },
          fail: () => {
            EventBus.$emit('dialog', true)
          }
        })
      } else {
        this.$store.dispatch('removeWishlistProduct', {
          data: Object.assign({}, product),
          success: () => {
            this.loadWishlistData()
          },
          fail: () => {}
        })
      }
    },
    loadWishlistData () {
      this.$store.dispatch('getWishlistData', {
        success: () => {},
        fail: () => {}
      })
    },
    searchProducts () {
      this.isLoading = true
      this.updateQuery(this.param)
      this.$store.dispatch('getProducts', {
        data: Object.assign({}, this.param),
        success: (res) => {
          console.log(res)
          this.isLoading = false
        },
        fail: () => {
          this.isLoading = false
        }
      })
    },
    loadPage (page) {
      this.param.page = page
      this.searchProducts()
    },
    viewDetail (id) {
      this.$router.push('/shops/detail/' + id)
    },
    getPath (fileName) {
      if (fileName === undefined) {
        return '/static/img/products/default-140.png'
      }
      console.log(fileName)
      return config.storage_base_path + fileName
    },
    share (product, shareable) {
      if (shareable.name === 'link') {
        let copyTarget = document.getElementById(product.id + '-' + product.name)
        copyTarget.select()
        document.execCommand('Copy')
      } else {
        // TODO: Push to share
      }
    }
  }
}
</script>
