// const BASE_PATH = 'https://api-staging.kopiketjil.com'; // staging
const BASE_PATH = 'https://api-staging.kopiketjil.com'; // production
const BASE_STORAGE_PATH = 'https://api-staging.kopiketjil.com'; // storage
const API_BASE_PATH = BASE_PATH + '/api';
const API_KOPI_KETJIL = API_BASE_PATH + '/kopiketjil';

export default {
  base_path: BASE_PATH,
  api_base_path: API_BASE_PATH,
  storage_base_path: BASE_STORAGE_PATH,
  api_kopi_ketjil: API_KOPI_KETJIL,
  api: {
    kopi_ketjil: {
      users: API_KOPI_KETJIL + '/users',
      posts: API_KOPI_KETJIL + '/post',
      carts: API_KOPI_KETJIL + '/cart',
      wishlists: API_KOPI_KETJIL + '/wishlist',
      categories: API_KOPI_KETJIL + '/category',
      galleries: API_KOPI_KETJIL + '/gallery',
      products: API_KOPI_KETJIL + '/product',
      transactions: API_KOPI_KETJIL + '/transaction',
      videos: API_KOPI_KETJIL + '/video',
      user: API_KOPI_KETJIL + '/user',
      login: API_KOPI_KETJIL + '/login',
      logout: API_KOPI_KETJIL + '/logout',
      register: API_KOPI_KETJIL + '/register',
      franchise: API_KOPI_KETJIL + '/franchise',
      product_category: API_KOPI_KETJIL + '/product-category',
      calculate_shipping_price: API_KOPI_KETJIL + '/calculate-shipping-price'
    }
  },
  app: {
    pages: {
      users: '/users'
    }
  }
}
