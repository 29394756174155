<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style src="../src/assets/css/font.css"></style>
<style src="../src/assets/css/style.css"></style>
<style src="../src/assets/css/animation.css"></style>
