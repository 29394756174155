<template>
  <div class="mb-3">
    <section><!--video-->
      <v-parallax src="/static/img/background-video.png" height="550">
        <v-layout column align-center justify-start class="white--text mr-5 mb-3 pt-3">
          <div class="white--text mt-5 text-md-center">
            <h1 class="text-executive-lg uppercase">Our Video</h1>
          </div>
        </v-layout>
        <v-layout column align-center justify-end class="mt-5">
          <iframe width="600" height="480" :src="video.video_url"
                  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </v-layout>
      </v-parallax>
    </section><!--/video-->
    <section>
      <v-flex xs8 offset-xs2>
        <v-container grid-list-xl class="white--text text-lg-center">
          <v-layout row wrap align-center justify-center>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 sm6 md3 :key="video.id" v-for="video in videos.data" class="gd-video pointer"
                        @click="changeVideo(video)">
                  <figure class="effect-gd pointer">
                    <img :src="getPath(video.image_url)" alt="Kopi Ketjil">
                    <figcaption>
                      <h4 class="effect1 text-executive-md">
                        <v-icon class="gd-play-btn" dark>play_circle_filled</v-icon>
                      </h4>
                    </figcaption>
                  </figure>
                </v-flex>
              </v-layout>
            </v-container>
          </v-layout>
        </v-container>
      </v-flex>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from "../config";

export default {
  name: 'gallery-video',
  data () {
    return {
      video: {
        name: 'Kopiketjil Main Video',
        video_url: 'https://www.youtube.com/embed/tFMgCrIEkuE'
      }
    }
  },
  created () {
    this.getVideos()
  },
  computed: {
    ...mapGetters(['videos'])
  },
  methods: {
    getVideos () {
      this.$store.dispatch('getVideos', {
        success: () => {
        },
        fail: () => {
        }
      })
    },
    getPath (fileName) {
      if (fileName === undefined) {
        return '/static/img/videos/video-preview-image.jpg'
      }
      return config.storage_base_path + fileName
    },
    changeVideo (video) {
      this.video = video
    }
  }
}
</script>
