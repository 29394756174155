<template>
  <v-content class="mb-5 gd-background">
    <section><!--header transaction-->
      <v-parallax src="/static/img/header-coffee.png" height="250" class="remove-padding">
        <v-layout column align-center justify-center>
          <div class="white--text text-md-center px-5">
            <h1 class="text-executive-lg">MY ORDERS</h1>
            <p class="text-courier-new-xs-white px-5"></p>
          </div>
        </v-layout>
      </v-parallax>
    </section><!--/header transaction-->

    <section class="mb-5"><!--grid transaction-->
      <v-layout column wrap>
        <v-flex xs12>
          <v-container grid-list-xl>
            <div>
              <table class="gd-table">
                <thead>
                <tr class="text-courier-new-md-black uppercase gd-head">
                  <th class="hidden-xs-only">No.</th>
                  <th>Product(s)</th>
                  <th>Shipping Information</th>
                  <th>Timestamp</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody v-if="transactionHistories.data">
                <tr v-if="transactionHistories.data.length === 0">
                  <td colspan="2" class="text-xs-center text-md-center gd-table-information">
                    There is no order, start shopping!
                  </td>
                </tr>
                <tr v-else-if="transactionHistories.data.length > 0" :key="transaction.id" v-for="(transaction, index) in transactionHistories.data" class="gd-product-list">
                  <td class="text-sm-center hidden-xs-only">
                    {{ index + 1 }}
                  </td>
                  <td class="text-sm-center">
                    <ol class="text-sm-left">
                      <li :key="product.id + '-' + transaction.id" v-for="product in transaction.products">
                        {{ product.name }} ({{ product.pivot.quantity }}pcs) - IDR{{ product.price * product.pivot.quantity }}
                      </li>
                    </ol>
                  </td>
                  <td class="text-sm-center">
                    <v-icon small>airport_shuttle</v-icon> {{ transaction.shipping || 'Waiting for Available Logistic' }}
                    <br>
                    <small>
                      Delivery to: {{ transaction.address }}, {{ transaction.zip_code }} ({{ transaction.country }})
                    </small>
                  </td>
                  <td class="text-sm-center">{{ transaction.created_at | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</td>
                  <td class="text-sm-center">{{ transaction.status || 'Order in Process' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </v-container>
        </v-flex>
      </v-layout>
    </section><!--/grid transaction-->
  </v-content>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'transaction-history',
  computed: {
    ...mapGetters(['transactionHistories'])
  },
  created () {
    this.loadTransactionHistories()
  },
  methods: {
    loadTransactionHistories () {
      this.$store.dispatch('getTransactions', {
        success: () => {},
        fail: () => {}
      })
    },
    goto (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
@media (max-width: 576px) {
  .gd-table th {
    font-size: 14px;
  }
}
.gd-table td {
  padding: 1.5vw;
}
</style>
