<template>
  <div>
    <table class="gd-table">
      <thead>
      <tr class="text-courier-new-md-black uppercase gd-head">
        <th>Items Description</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="wishlist.products.length === 0">
        <td colspan="2" class="text-xs-center text-md-center gd-table-information">
          There is no product(s) in your wishlist, start shopping!
        </td>
      </tr>
      <tr v-else-if="wishlist.products.length > 0" :key="product.id" v-for="product in wishlist.products" class="gd-product-list">
        <td>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap>
                <v-flex xs12 md3 v-if="product.product_images && product.product_images.length > 0">
                  <img :src="getPath(product.product_images[0].image_url)" alt="Kopi Ketjil" width="80">
                </v-flex>
                <v-flex xs12 md9>
                  <h4 class="text-executive-xs uppercase pointer">
                    {{ product.name }}
                    <v-icon color="red" @click="remove(product)" class="pointer">
                      close
                    </v-icon>
                  </h4>
                  <p class="text-courier-new-xs-black">IDR {{ product.price }}</p>
                  <small class="text-courier-new-xxxs-black">{{ product.description }}</small>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </td>
        <td>
          <v-btn @click="viewDetail(product.id)" dark>View Detail</v-btn>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'wishlist-detail',
  computed: {
    ...mapGetters(['wishlist'])
  },
  created () {
    this.loadWishlistData()
  },
  methods: {
    loadWishlistData () {
      this.$store.dispatch('getWishlistData', {
        success: () => {},
        fail: () => {}
      })
    },
    getPath (fileName) {
      return '/static/img/products/' + fileName
    },
    remove (product) {
      this.wishlist.products.splice(this.wishlist.products.indexOf(product), 1)
      this.$store.dispatch('removeWishlistProduct', {
        data: Object.assign({}, product),
        success: () => {
          this.$toast('Wishlist product has been successfully removed')
          this.loadWishlistData()
        },
        fail: () => {
          this.$toast('Something went wrong when try to deleting product')
        }
      })
    },
    viewDetail (id) {
      this.$router.push('/shops/detail/' + id)
    },
    goto (path) {
      this.$router.push(path)
    }
  }
}
</script>
