<template>
  <v-content class="mb-5 gd-background">
    <section><!--header cart-->
      <v-parallax src="/static/img/header-coffee.png" height="250" class="remove-padding">
        <v-layout column align-center justify-center>
          <div class="white--text text-md-center px-5">
            <h1 class="text-executive-lg">CART</h1>
            <p class="text-courier-new-xs-white px-5"></p>
          </div>
        </v-layout>
      </v-parallax>
    </section><!--/header cart-->

    <section class="mb-5"><!--grid cart-->
      <v-layout column wrap>
        <v-flex xs12>
          <v-container grid-list-xl>
            <router-view></router-view>
          </v-container>
        </v-flex>
      </v-layout>
    </section><!--/grid cart-->
  </v-content>
</template>

<script>
export default {
  name: 'cart'
}
</script>
