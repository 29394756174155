<template>
  <div>
    <div class="mb-5 gd-background">
      <section><!--franchise-->
        <div style="background-image: url(/static/img/background-franchise.png);" class="px-3">
          <v-layout column align-center justify-start class="white--text mr-5 mb-3 pt-3">
            <div class="white--text mt-5 text-md-center">
              <h1 class="text-executive-lg uppercase text-shadow">Why Kopi Ketjil?</h1>
            </div>
          </v-layout>
          <v-layout column align-center justify-end class="mt-5">
            <div class="gd-blue-franchise gd-middle-box">
              <div class="text-morsal-lg mb-5 text-md-center"> <!-- gd-rotate-3"> -->
                <h1>
                 Your Coffee Shop Ownership Starts Here
                </h1>
              </div>
              <div class="text-courier-new-xs-white text-md-center">
                Have you ever walked into a coffee shop and thought to yourself: Self, I’d love to own a place like this.
                An inclusive place where people from anywhere gather over coffee, food, and community?
                Then we might be the coffee shop brand you’re looking for.
                An inclusive place where people from anywhere gather over coffee, food, and community?
                Then we might be the coffee shop brand you’re looking for.
              </div>
            </div>
          </v-layout>
        </div>
      </section><!--/franchise-->
      <section>
        <v-flex xs8 offset-xs2>
          <v-container grid-list-xl class="white--text text-lg-center">
            <v-layout row wrap align-center justify-center>
              <v-container grid-list-xl>
                <v-layout row wrap align-center>
                  <v-flex xs12 md4>
                    <v-card color="transparent" flat>
                      <v-card-title primary-title>
                        <div>
                          <img src="/static/img/coffee-135.png" alt="">
                          <h3 class="headline mb-2 text-executive-lg-black uppercase bold">Best Coffee</h3>
                          <div class="text-courier-new-xs-black">Have you ever walked into a coffee shop and thought to yourself: Self, I’d love to own a place like this. </div>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card color="transparent" flat>
                      <v-card-title primary-title>
                        <div>
                          <img src="/static/img/coffee-135.png" alt="">
                          <h3 class="headline mb-2 text-executive-lg-black uppercase bold">Best Food</h3>
                          <div class="text-courier-new-xs-black">Have you ever walked into a coffee shop and thought to yourself: Self, I’d love to own a place like this. </div>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card color="transparent" flat>
                      <v-card-title primary-title>
                        <div>
                          <img src="/static/img/coffee-135.png" alt="">
                          <h3 class="headline mb-2 text-executive-lg-black uppercase bold">Best Concept</h3>
                          <div class="text-courier-new-xs-black">Have you ever walked into a coffee shop and thought to yourself: Self, I’d love to own a place like this. </div>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-layout>
          </v-container>
        </v-flex>
      </section>
    </div>
    <div class="mb-5">
      <section class="text-md-center text-xs-center">
        <h1 class="text-helvetica-xlg uppercase">Submit Inquiry</h1>
        <v-card flat mb-5>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap class="gd-padding-control">
                <v-flex xs12 md6 class="text-courier-new-xs">
                  <v-text-field label="First Name" required v-model="franchiseParam.first_name" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="text-courier-new-xs">
                  <v-text-field label="Last Name" required v-model="franchiseParam.last_name" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 class="text-courier-new-xs">
                  <v-text-field label="Email Address" type="email" required v-model="franchiseParam.email" hide-details prepend-icon="email"></v-text-field>
                </v-flex>
                <v-flex xs12 class="text-courier-new-xs">
                  <v-text-field label="Address" type="text" required v-model="franchiseParam.address" hide-details prepend-icon="location_on"></v-text-field>
                </v-flex>
                <v-flex xs12 class="text-courier-new-xs">
                  <v-text-field label="Country" type="text" required v-model="franchiseParam.country" hide-details prepend-icon="location_city"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="text-courier-new-xs">
                  <v-text-field label="Phone" type="text" required v-model="franchiseParam.phone_number" hide-details prepend-icon="phone"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="text-courier-new-xs">
                  <v-text-field label="Mobile Phone" type="text" required v-model="franchiseParam.mobile_phone" hide-details prepend-icon="smartphone"></v-text-field>
                </v-flex>
                <v-flex xs12 class="text-courier-new-xs">
                </v-flex>
                <v-flex xs12 mt-5>
                  <v-btn dark class="text-courier-new-xs-white remove-margin" medium @click.stop="submitFranchise" :disabled="isSubmitting == 1">Submit</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </section>
    </div>
    <FooterApps />
  </div>
</template>

<script>
import FooterApps from '@/components/FooterApps'

export default {
  name: 'franchise',
  data () {
    return {
      franchiseParam: {},
      isSubmitting: 0
    }
  },
  components: {
    FooterApps
  },
  methods: {
    submitFranchise () {
      this.isSubmitting = 1
      this.$store.dispatch('submitFranchise', {
        data: Object.assign({}, this.franchiseParam),
        success: () => {
          this.$toast('Submit success! Your data will be processed by out franchise management team.')
          this.resetForm()
        },
        fail: () => {
          this.$toast('Please fill all required field(s) correctly')
          this.isSubmitting = 0
        }
      })
    },
    resetForm () {
      this.franchiseParam = {}
      this.isSubmitting = 0
    }
  }
}
</script>
