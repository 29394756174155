<template>
  <div>
    <v-content class="gd-background">
      <section><!--about-us-->
        <v-layout wrap align-center class="mb-5">
            <v-flex xs12>
              <v-container grid-list-xl>
                <v-layout row wrap align-center>
                  <v-flex xs12 md6 class="text-xs-left">
                    <div class="text-executive-lg black--coffee uppercase mb-5">
                      <h5>Background</h5>
                    </div>
                    <div class="text-courier-new-xs black--coffee mb-5">
                      Kopi Ketjil itself means ‘small coffee’, small bean that has a great value.
                      Kopi ketjil was launched in 2014 following the small steps that had been started since 2010.
                      Managed by mostly young people, who are students in many disciplines. We grew, process, roast, brew and serve coffee.
                      We brew coffee that we roast ourselves, using the best Indonesian coffee directly from the Indonesian archipelago farmers.
                      We provide farmers with seeds and knowledge in the coffee plantation so they can grow coffee with right methods.
                      We buy red and green bean coffee with decent price in order to support the farmer’s family to have more money to raise their family.
                      Together with local farmers community, we prepare the best Indonesian green bean to be roasted in Kopi Ketjil’s roasters.
                      We roast with heart and passion from our humble roasters located in Jogjakarta, INDONESIA.
                      We also do small batch customized coffee roasting focusing in the best single origin Indonesian coffee.
                      We share our love for Indonesian coffee to everybody who love to stopping by.
                    </div>
                    <div>
                      <iframe style="width: 100%;" height="300"
                              src="https://www.youtube.com/embed/tFMgCrIEkuE?rel=0"
                              frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    </div>
                  </v-flex>
                  <v-flex xs12 md6 class="text-xs-right">
                    <img src="/static/img/about-us-full.png" alt="About Kopi Ketjil" style="width: 100%;">
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
      </section><!--/about-us-->
    </v-content>
    <v-content class="gd-background">
      <section>
        <v-parallax src="/static/img/background-about-us-full.png" height="800">
          <v-layout column align-end justify-start class="white--text mr-5 pt-5">
            <div class="white--text mb-3 text-md-right">
              <h4 class="text-executive-xs">INDONESIAN Community Based</h4>
              <h4 class="text-executive-xs mb-5">Artisan Coffee and Roasters</h4>
              <div class="text-morsal-lg mb-5 text-shadow"> <!-- gd-rotate-3"> -->
                <h1>
                  “If you support <br>
                  a small community <br>
                  based business, <br>
                  you support a dream <br>
                  with a vision”
                </h1>
              </div>
              <div class="text-courier-new-lg-white-bold">
                Octaviana Rombe<br>
                <small>Founder</small>
              </div>
            </div>
          </v-layout>
        </v-parallax>
      </section>
      <section><!--vision-->
        <v-layout column wrap class="my-5" align-center>
          <v-flex xs12 class="mb-5">
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md6 class="text-xs-left">
                  <img src="/static/img/about-us-short.png" alt="About Kopi Ketjil" style="width: 100%;">
                </v-flex>
                <v-flex xs12 md6 class="text-xs-left">
                  <div class="text-executive-lg black--coffee uppercase mb-5">
                    <h5>Vision & Mission</h5>
                  </div>
                  <div class="text-courier-new-xs black--coffee mb-5">
                    We start small with a big dream, we stand strong for our vision.
                    And believe our vision wouldn’t be judged by the size of room and capital we invest but by the dream we have
                    and the vision we set and we love to grow with a strong root.
                    Our mission through small steps that we have done include open discussion with farmers community,
                    brought them a coffee seeds and organic fertilizer, discuss how to plant and harvesting with right method,
                    buy the green bean with descent price so they have spare money to raise the family. As our roots as community became stronger,
                    we decided to be a great roasters who roast only the best Indonesian coffee and brew in manual way and embrace the local way of brewing.
                    As one of the third wave coffee and roasters , we appreciate the SCAE , Specialty Coffee Association of Europe,
                    as one of our guidance through
                    our journey in the world of coffee.
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex xs12 class="soft-gray-background px-5 gd-size-modified">
            <v-container>
              <v-layout row wrap align-center>
                <v-flex xs12 md3>
                  <img src="/static/img/smart-phone-183.png" alt="Smart Phone" class="margin-top-minus-5">
                </v-flex>
                <v-flex xs12 md6>
                  <v-container grid-list-md>
                    <v-layout row wrap align-center>
                      <v-flex xs12 md3>
                        <img src="/static/img/logo-circle-96.png" alt="Kopi Ketjil Circle">
                      </v-flex>
                      <v-flex xs12 md9>
                        <h2 class="text-executive-md uppercase">
                          Kopi Ketjil In Your Hand!
                        </h2>
                        <h2 class="text-courier-new-xs">
                          Download Kopi Ketjil app now for exclusive
                          goodies, plus a free brewed coffee or hot
                          tea to welcome you into the fold.
                        </h2>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
                <v-flex xs12 md3>
                  <div class="my-2">
                    <a href="">
                      <img src="/static/img/google-play-badge.png" alt="Google Play" height="40">
                    </a>
                  </div>
                  <div class="my-2">
                    <a href="">
                      <img src="/static/img/app-store-badge.png" alt="App Store" height="40">
                    </a>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section><!--/vision-->
    </v-content>
  </div>
</template>

<script>
export default {
  name: 'about-us'
}
</script>
