<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="remove-padding">
          <v-container grid-list-md class="remove-botton-padding">
            <v-layout wrap align-center>
              <v-flex xs12 md6>
                <img src="/static/img/logo-sd-175.png" alt="Kopi Ketjil Logo" height="64" class="left">
              </v-flex>
              <v-flex xs12 md6 class="text-md-right pl-3">
                <span class="headline text-helvetica-xxs uppercase">Welcome to Kopi Ketjil</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md6 class="border-right">
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <h4 class="text-executive-xs uppercase">Create an Account</h4>
                    </v-flex>
                    <v-flex xs12 md6 class="text-courier-new-xs">
                      <v-text-field label="First Name" required hide-details v-model="registerParam.first_name"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 class="text-courier-new-xs">
                      <v-text-field label="Last Name" required hide-details v-model="registerParam.last_name"></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="text-courier-new-xs">
                      <v-text-field label="Email Address" type="email" required hide-details v-model="registerParam.email"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 class="text-courier-new-xs">
                      <v-text-field label="Your Password" type="password" required hide-details v-model="registerParam.password"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 class="text-courier-new-xs">
                      <v-text-field label="Confirm Password" type="password" required hide-details v-model="registerParam.password_confirmation"></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn dark class="text-helvetica-xxs remove-margin" small @click.stop="register">Sign up now</v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex xs12 md6>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <h4 class="text-executive-xs uppercase">Login to your account</h4>
                    </v-flex>
                    <v-flex xs12 class="text-courier-new-xs">
                      <v-text-field label="Your Email" required hide-details v-model="loginParam.email"></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="text-courier-new-xs">
                      <v-text-field label="Your Password" type="password" required hide-details v-model="loginParam.password" @keyup.enter.native="login"></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="text-courier-new-xxxs">
                      <a href="#forgotPassword" class="link">Forgot your password?</a>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn dark class="text-helvetica-xxs remove-margin" small @click.stop="login">Login</v-btn>
                      <!-- <v-btn color="primary remove-margin" small>
                        <i class="fa fa-facebook-square left icon material-icons mr-2" aria-hidden="true"></i>
                        <v-icon>facebook</v-icon>
                        Log in with facebook
                      </v-btn> -->
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <small>*indicates required field</small>
          <v-btn color="blue darken-1" flat @click.native="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  name: 'login',
  mounted: function () {
    let vm = this
    EventBus.$on('dialog', function (value) {
      vm.dialog = value
    })
  },
  data () {
    return {
      dialog: false,
      registerParam: {},
      loginParam: {}
    }
  },
  computed: {

  },
  methods: {
    login () {
      this.$store.dispatch('login', {
        data: Object.assign({}, this.loginParam),
        success: (res) => {
          const STORAGE_KEY = 'kopiketjil_external'
          EventBus.$emit('dialog', false)
          localStorage.setItem(STORAGE_KEY, JSON.stringify(res))
          this.resetForm()
          this.fetchUser()
          window.location.href = '/'
        },
        fail: () => {
          this.$toast('Credentials not found')
        }
      })
    },
    fetchUser () {
      this.$store.dispatch('getUser', {
        data: {},
        success: () => {},
        fail: () => {}
      })
    },
    register () {
      this.$store.dispatch('register', {
        data: Object.assign({}, this.registerParam),
        success: () => {
          this.loginParam = {
            email: this.registerParam.email,
            password: this.registerParam.password
          }
          this.login()
          this.resetForm()
        },
        fail: () => {
          this.$toast('Please fill all required field(s)')
        }
      })
    },
    resetForm () {
      this.registerParam = {}
      this.loginParam = {}
    }
  }
}
</script>

<style scoped>
.border-right {
}
</style>
