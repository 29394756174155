<template>
  <div>
    <div class="mb-3 gd-background">
      <section><!--franchise-->
        <v-parallax src="/static/img/background-apps.png" height="550">
          <v-layout column align-start justify-start class="white--text pl-5 pt-5">
            <v-flex xs12 md12 offset-md2>
              <div class="white--text text-md-left">
                <h1 class="text-executive-lg uppercase text-shadow">
                  Get the <br>
                  <span class="gd-blue-text"> KopiKetjil App </span> <br>
                  for iphone and <br>
                  android</h1>
                <p class="text-courier-new-xs-white">
                  Our apps let you conveniently pay for purchases, <br>
                  earn Stars and redeem rewards with My KopiKetjil, <br>
                  find stores, and so much more. Click below <br>
                  to download an app.
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-parallax>
      </section><!--/franchise-->
      <section>
        <v-flex xs12>
          <v-container grid-list-xl class="white--text text-md-left">
            <v-layout row wrap align-center justify-center>
              <v-container grid-list-xl>
                <v-layout row wrap align-center>
                  <v-flex xs12 md6>
                    <v-card color="transparent" flat>
                      <v-card-title primary-title>
                        <div>
                          <h3 class="headline mb-2 text-executive-lg-black uppercase bold">KOPI KETJIL APP FOR IPHONE</h3>
                          <v-container grid-list-xl>
                            <v-layout row wrap align-start>
                              <v-flex xs12 md4>
                                <img src="/static/img/smart-phone-pink-150.png" alt="">
                              </v-flex>
                              <v-flex xs12 md8 pl-5>
                                <div class="text-courier-new-xs-black">
                                  Our apps let you <br>
                                  conveniently pay for purchases, <br>
                                  earn Stars and redeem rewards with <br>
                                  My KopiKetjil, find stores, <br>
                                  and so much more. <br>
                                  Click below to <br>
                                  download an app.
                                </div>
                                <div class="mt-4">
                                  <div><b>Coming Soon!</b></div>
                                  <a href="">
                                    <img src="/static/img/app-store-badge.png" alt="App Store" height="40">
                                  </a>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-card color="transparent" flat>
                      <v-card-title primary-title>
                        <div>
                          <h3 class="headline mb-2 text-executive-lg-black uppercase bold">KOPI KETJIL APP FOR ANDROID</h3>
                          <v-container grid-list-xl>
                            <v-layout row wrap align-start>
                              <v-flex xs12 md4>
                                <img src="/static/img/smart-phone-pink-150.png" alt="">
                              </v-flex>
                              <v-flex xs12 md8 pl-5>
                                <div class="text-courier-new-xs-black">
                                  Our apps let you <br>
                                  conveniently pay for purchases, <br>
                                  earn Stars and redeem rewards with <br>
                                  My KopiKetjil, find stores, <br>
                                  and so much more. <br>
                                  Click below to <br>
                                  download an app.
                                </div>
                                <div class="mt-4">
                                  <div><b>Coming Soon!</b></div>
                                  <a href="">
                                    <img src="/static/img/google-play-badge.png" alt="Google Play" height="40">
                                  </a>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-layout>
          </v-container>
        </v-flex>
      </section>
    </div>
    <!-- <FooterApps /> -->
  </div>
</template>

<script>
import FooterApps from '@/components/FooterApps'

export default {
  name: 'apps',
  components: {
    FooterApps
  }
}
</script>
