<template>
  <div>
    <section><!--news feed-->
      <v-parallax src="/static/img/background-news-feed.png" height="300">
        <v-layout column align-center justify-center class="white--text mr-5">
          <div class="white--text mb-3 text-md-center">
            <h1 class="text-executive-lg uppercase">News Feed</h1>
          </div>
        </v-layout>
      </v-parallax>
    </section><!--/news feed-->

    <section><!--feed-->
      <v-layout row wrap px-5>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap>
              <v-flex xs12 md12>
                <v-card color="transparent"
                        class="white--text mb-3">
                  <v-container fluid grid-list-lg class="gd-border-solid">
                    <div class="gd-date-absolute text-executive-md text-md-center">
                      <div class="date-year">{{ currentPost.data[0].created_at | moment("YY") }}</div>
                      <div class="date-day">{{ currentPost.data[0].created_at | moment("MM") }}</div>
                    </div>
                    <v-layout row>
                      <v-flex xs3>
                        <v-card-media
                          :src="getPath(currentPost.data[0].image_url)"
                          height="125px"
                          width="210px"
                          contain
                        ></v-card-media>
                      </v-flex>
                      <v-flex xs7>
                        <div>
                          <div class="headline mb-2 text-executive-lg-black bold">{{ currentPost.data[0].title }}</div>
                          <div class="mb-2 text-courier-new-xs-black wordwrap">{{ currentPost.data[0].content }}</div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section><!--/feed-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'news-single',
  data () {
    return {
      param: {}
    }
  },
  created () {
    this.param.id = this.$route.params.newsFeedId
    this.getCurrentPost()
  },
  computed: {
    ...mapGetters(['currentPost'])
  },
  methods: {
    getCurrentPost () {
      this.$store.dispatch('getCurrentPost', {
        data: Object.assign({}, this.param),
        success: () => {},
        fail: () => {}
      })
    },
    getPath (fileName) {
      if (fileName === undefined) {
        return '/static/img/products/default-210.png'
      }
      return '/static/img/news/' + fileName
    }
  }
}
</script>
