import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

let state = {
  loading: true,
  categories: [],
  user: {},
  currentUserInformation: {},
  currentProduct: {},
  products: [],
  newsFeeds: {},
  posts: {
    data: [
      {
        id: 0,
        title: 'Back In That Day?',
        description: 'Back in the day, when our up-and-coming coffee shop was',
        createdDate: '2018-03-05',
        image: 'default-96.png'
      },
      {
        id: 1,
        title: 'Back In That Day?',
        description: 'Back in the day, when our up-and-coming coffee shop was',
        createdDate: '2018-03-05',
        image: 'default-96.png'
      },
      {
        id: 2,
        title: 'Back In That Day?',
        description: 'Back in the day, when our up-and-coming coffee shop was',
        createdDate: '2018-03-05',
        image: 'default-96.png'
      }
    ]
  },
  currentPost: {},
  recentPosts: {},
  recentNews: {},
  recentProducts: {},
  transactionHistories: [],
  cart: {
    products: [],
    totalPrice: 0
  },
  shipmentInformation: {},
  wishlist: {
    products: []
  },
  videos: {},
  galleries: [],
  franchise: [],
  product_serving_type: [],
  product_roasted_type: {},
  product_grind_size: {},
  shipping: {}
}

let getters = {
  loading: state => state.loading,
  categories: state => state.categories,
  user: state => state.user,
  currentUserInformation: state => state.currentUserInformation,
  currentProduct: state => state.currentProduct,
  products: state => state.products,
  newsFeeds: state => state.newsFeeds,
  posts: state => state.posts,
  currentPost: state => state.currentPost,
  recentPosts: state => state.recentPosts,
  recentNews: state => state.recentNews,
  recentProducts: state => state.recentProducts,
  transactionHistories: state => state.transactionHistories,
  cart: state => state.cart,
  shipmentInformation: state => state.shipmentInformation,
  wishlist: state => state.wishlist,
  videos: state => state.videos,
  galleries: state => state.galleries,
  franchise: state => state.franchise,
  product_serving_type: state => state.product_serving_type,
  product_roasted_type: state => state.product_roasted_type,
  product_grind_size: state => state.product_grind_size
}

let mutations = {
  setUser (state, value) {
    state.user = value
  },
  setLoading (state, value) {
    state.loading = value
  },
  setCurrentUserInformation (state, value) {
    state.currentUserInformation = value
  },
  setWishlist (state, value) {
    state.wishlist = value
  },
  setCart (state, value) {
    // temporary
    // if (state.cart.products.filter(product => {
    //   return parseInt(product.id) === parseInt(value.id)
    // }).length === 0) {
    //   value.quantity = 1
    //   value.subTotal = value.quantity * value.price
    //   state.cart.products.push(value)
    // } else {
    //   state.cart.products.filter(product => {
    //     return parseInt(product.id) === parseInt(value.id)
    //   })[0].quantity += 1
    // }
    state.cart = value
  },
  setShipmentInformation (state, value) {
    state.shipmentInformation = value
  },
  setCurrentProduct (state, value) {
    state.currentProduct = value
  },
  setTransactionHistories (state, value) {
    state.transactionHistories = value
  },
  setProducts (state, value) {
    state.products = value
  },
  setNewsFeed (state, value) {
    state.newsFeeds = value
  },
  setCurrentPost (state, value) {
    state.currentPost = value
    console.log('bgsd')
    console.log(value.data)
  },
  setRecentPosts (state, value) {
    state.recentPosts = value
  },
  setRecentNews (state, value) {
    state.recentNews = value
  },
  setRecentProducts (state, value) {
    state.recentProducts = value
  },
  setGalleries (state, value) {
    state.galleries = value
  },
  setVideos (state, value) {
    state.videos = value
  },
  setCategories (state, value) {
    state.categories = value
  },
  setFranchise (state, value) {
    state.franchise = value
  },
  setServingType (state, value) {
    state.product_serving_type = value
  },
  setRoastedType (state, value) {
    state.product_roasted_type = value
  },
  setGrindSize (state, value) {
    state.product_grind_size = value
  },
  setShipping (state, value) {
    state.shipping = value
  }
}

let actions = {
  removeWishlistProduct ({commit}, {data, success, fail} = {}) {
    api.removeWishlistProduct((res) => {
      if (success) success(res)
    }, data, fail)
  },
  getWishlistData ({commit}, {success, fail} = {}) {
    api.getWishlistData((res) => {
      commit('setWishlist', res.data.data)
      if (success) success(res)
    }, fail)
  },
  addToWishlist ({commit}, {data, success, fail} = {}) {
    api.addToWishlist((res) => {
      if (success) success(res)
    }, data, fail)
  },
  getTransactions ({commit}, {data, success, fail} = {}) {
    api.getTransactions((res) => {
      commit('setTransactionHistories', res.data)
      if (success) success(res)
    }, data, fail)
  },
  checkoutTransaction ({commit}, {data, success, fail} = {}) {
    api.checkoutTransaction(success, data, fail)
  },
  removeCartProduct ({commit}, {data, success, fail} = {}) {
    api.removeCartProduct((res) => {
      if (success) success(res)
    }, data, fail)
  },
  getCartData ({commit}, {success, fail} = {}) {
    api.getCartData((res) => {
      commit('setCart', res.data.data)
      if (success) success(res)
    }, fail)
  },
  addToCart ({commit}, {data, success, fail} = {}) {
    api.addToCart((res) => {
      if (success) success(res)
    }, data, fail)
  },
  updateCartProductQuantity ({commit}, {data, success, fail} = {}) {
    api.updateCartProductQuantity(success, data, fail)
  },
  setShipmentInformation ({commit}, {data, success, fail} = {}) {
    commit('setShipmentInformation', data)
  },
  resetShipmentInformation ({commit}, {data, success, fail} = {}) {
    commit('setShipmentInformation', {})
  },
  getUser ({commit}, {data, success, fail} = {}) {
    api.getUser((res) => {
      commit('setCurrentUserInformation', res.data)
      if (success) success(res)
    }, data, fail)
  },
  login ({commit}, {data, success, fail} = {}) {
    api.login((res) => {
      commit('setUser', res.data)
      if (success) success(res)
    }, data, fail)
  },
  register ({commit}, {data, success, fail} = {}) {
    api.register((res) => {
      if (success) success(res)
    }, data, fail)
  },
  logout ({commit}, {data, success, fail} = {}) {
    api.logout((res) => {
      commit('setUser', {})
      if (success) success(res)
    }, data, fail)
  },
  getCurrentProduct ({commit}, {data, success, fail} = {}) {
    api.getProductSpecific((res) => {
      commit('setCurrentProduct', res.data.data)
      if (success) success(res)
    }, data, fail)
  },
  getProducts ({commit}, {data, success, fail} = {}) {
    api.getProducts((res) => {
      commit('setProducts', res.data.data)
      if (success) success(res)
    }, data, fail)
  },
  getNewsFeed ({commit}, {data, success, fail} = {}) {
    api.getNewsFeed((res) => {
      commit('setNewsFeed', res.data)
      if (success) success(res)
    }, data, fail)
  },
  getCurrentPost ({commit}, {data, success, fail} = {}) {
    api.getCurrentPost((res) => {
      commit('setCurrentPost', res.data)
      if (success) success(res)
    }, data, fail)
  },
  getRecentPosts ({commit}, {data, success, fail} = {}) {
    api.getRecentPosts((res) => {
      commit('setRecentPosts', res.data)
      if (success) success(res)
    }, data, fail)
  },
  getRecentNews ({commit}, {data, success, fail} = {}) {
    api.getRecentNews((res) => {
      commit('setRecentNews', res.data)
      if (success) success(res)
    }, data, fail)
  },
  getRecentProducts ({commit}, {data, success, fail} = {}) {
    api.getRecentProducts((res) => {
      commit('setRecentProducts', res.data)
      if (success) success(res)
    }, data, fail)
  },
  getGalleries ({commit}, {data, success, fail} = {}) {
    api.getGalleries((res) => {
      commit('setGalleries', res.data)
      if (success) success(res)
    }, data, fail)
  },
  getVideos ({commit}, {data, success, fail} = {}) {
    api.getVideos((res) => {
      commit('setVideos', res.data)
      if (success) success(res)
    }, data, fail)
  },
  getCategories ({commit}, {data, success, fail} = {}) {
    api.getCategories((res) => {
      commit('setCategories', res.data.data)
      if (success) success(res)
    }, data, fail)
  },
  submitFranchise ({commit}, {data, success, fail} = {}) {
    api.submitFranchise((res) => {
      if (success) success(res)
    }, data, fail)
  },
  getServingType ({commit}, {data, success, fail} = {}) {
    api.getServingType((res) => {
      commit('setServingType', res.data.data)
      if (success) success(res)
    }, data, fail)
  },
  getRoastedType ({commit}, {data, success, fail} = {}) {
    api.getRoastedType((res) => {
      commit('setRoastedType', res.data.data)
      if (success) success(res)
    }, data, fail)
  },
  getGrindSize ({commit}, {data, success, fail} = {}) {
    api.getGrindSize((res) => {
      commit('setGrindSize', res.data.data)
      if (success) success(res)
    }, data, fail)
  },
  getCalculateShipping ({commit}, {data, success, fail} = {}) {
    api.getCalculateShipping((res) => {
      commit('setShipping', res.data.data)
      if (success) success(res)
    }, data, fail)
  },
  createToken ({commit}, {data, success, fail} = {}) {
    api.createToken(success, data, fail)
  },
}

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters
})

export default store
