<template>
  <v-content class="gray-background">
    <!--temporary dialog for under construction-->
    <v-dialog v-model="underConstruction" max-width="500px">
      <v-card>
        <v-card-title>
          <span>We're still on development and construction</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions class="text-md-center">
          <v-btn color="primary" flat @click.stop="underConstruction=false">
            <v-icon>build</v-icon>
            &nbsp; Coffee Ketjil is Coming Soon</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/TODO: remove this before deploy-->

    <section><!--slider-->
      <v-carousel interval="1000000">
        <v-carousel-item v-for="i in 3" :key="i">
          <v-parallax src="/static/img/slider-1.png" height="600">
            <v-layout column align-end justify-center>
              <v-flex xs6 md6 offset-md6>
                <div class="white--text mb-3 text-md-left  text-xs-center px-5">
                  <h5 class="text-helvetica-xs">WHAT'S NEW</h5>
                  <h1 class="text-executive-lg">INDONESIAN</h1>
                  <h1 class="text-executive-lg">SPECIALTY COFFEE</h1>
                  <h5 class="text-morsal-xs">From Indonesia to Abu Dhabi with Love</h5>
                  <v-btn class="blue lighten-2" dark large @click="goto('/news/hall')">
                    Get Details
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-parallax>
        </v-carousel-item>
      </v-carousel>
    </section><!--/slider-->

    <section><!--welcome-->
      <v-parallax src="/static/img/background-blue.png" height="auto">
          <v-container grid-list-xl class="white--text text-lg-center">
            <v-layout row wrap align-center justify-center>
                <v-card class="elevation-0 transparent text-xs-center">
                  <v-card-text class="layout justify-center">
                    <img src="/static/img/logo-175-white.png" alt="Kopi Ketjil White">
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <h1 class="text-executive-lg-black">WELCOMES YOU!</h1>
                  </v-card-title>
                  <v-card-text class="layout justify-center mb-1">
                    <h5 class="text-morsal-xs">Your Coffee Shop Ownership Starts Here</h5>
                  </v-card-text>
                  <v-card-text class="text-courier-new-xs-black-bold px-5 mb-3">
                    Have you ever walked into a coffee shop and thought to yourself: Self, I’d love to own a place like this. An inclusive place where people from anywhere gather over coffee, food, and community?
                    Then we might be the coffee shop brand you’re looking for.
                  </v-card-text>
                  <v-card-actions class="layout justify-center">
                    <v-btn class="black" dark large @click="goto('/franchise')">
                      Franchise Here!
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-layout>
          </v-container>
      </v-parallax>
    </section><!--/welcome-->

    <section><!--about-us-->
      <v-parallax src="/static/img/background-about-us.png" height="auto">
        <v-layout wrap align-center class="my-5">
          <v-flex xs12 sm4>
            <div class="text-xs-center">
              <h2 class="headline text-helvetica uppercase black--coffee bold">Our Shop</h2>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md4 :key="shop.name" v-for="shop in shops">
                  <div class="gd-container">
                    <img :src="shop.img" :alt="shop.name" @click="goto('/shops/')">
                    <div class="centered text-executive-md uppercase">{{ shop.name }}</div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex xs12 sm4 class="mt-5 mb-2">
            <div class="text-xs-center">
              <h2 class="headline text-helvetica uppercase black--coffee bold">About Us</h2>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md6 class="text-xs-right">
                  <div class="text-executive-md black--coffee">
                    <b>INDONESIAN Community Based Artisan Coffee and Roasters</b>
                  </div>
                  <div class="text-courier-new-xs black--coffee mb-3">
                    Back in the day, when our up-and-coming coffee shop was just making first rounds among numerous coffee junkies in the local area,
                    a concept of our operations has been developed.
                  </div>
                  <v-btn class="dark" dark large @click="goto('about-us/background')">
                    Get To Know
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6>
                  <img src="/static/img/about-us.png" alt="About Kopi Ketjil" class="flex xs12">
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-parallax>
    </section><!--/about-us-->

    <section><!--gallery-->
      <v-parallax src="/static/img/background-gallery.png" height="480">
        <v-layout column align-center justify-center>
          <div class="headline white--text mb-5 text-xs-center uppercase text-helvetica">Gallery</div>
          <div class="subheading text-courier-new-xs-white-bold text-sm-center">
            <p>We’re proud of the range of both the coffee beverages that <br>
            our cafe has, just as well as the range of snacks, pastry <br>
            and lunches that we also do offer!</p>
          </div>
          <v-btn class="blue lighten-2 mt-5" dark large @click="goto('/gallery/menu')">
            Visit Us
          </v-btn>
        </v-layout>
      </v-parallax>
    </section><!--/gallery-->

    <section><!--news-->
      <v-layout wrap align-center pt-5>
        <v-flex xs12 sm4 class="my-3">
          <div class="text-xs-center">
            <h2 class="headline text-helvetica uppercase bold">FRESH COFFEE SHOP NEWS</h2>
          </div>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <v-container grid-list-xl>
            <v-layout row wrap align-center >
              <v-flex xs12 md4 :key="index" v-for="(coffeeNew, index) in recentNews.data">
                <v-card class="elevation-0 transparent text-xs-center">
                  <v-card-text class="text-xs-center">
                    <div class="uppercase text-helvetica-lg bold">{{ coffeeNew.date }}</div>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center text-courier-new-xs-black-bold uppercase">{{ coffeeNew.title }}</div>
                  </v-card-title>
                  <v-card-text class="text-courier-new-xs wordwrap">
                    {{ coffeeNew.content }}
                  </v-card-text>
                  <v-card-text>
                    <v-btn class="dark" dark large @click="viewDetail(coffeeNew.content)">
                      Read More
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
        <FooterApps />
      </v-layout>
    </section><!--/news-->
  </v-content>
</template>

<script>
import {mapGetters} from 'vuex'
import FooterApps from '@/components/FooterApps'

export default {
  name: 'index',
  data () {
    return {
      underConstruction: false,
      shops: [
        {name: 'Coffee', img: '/static/img/store-coffee.png'},
        {name: 'Tea', img: '/static/img/store-tea.png'},
        {name: 'Chocolate', img: '/static/img/store-chocolate.png'},
        {name: 'Equipment', img: '/static/img/store-tea.png'},
        {name: 'Merchandise', img: '/static/img/store-chocolate.png'},
        {name: 'Coffee Gift', img: '/static/img/store-gift.png'}
      ],
      coffeeNews: [
        {date: 'Aug 8, 2017', title: 'Lorem Ipsum', description: 'Lorem ipsum dolor sit amet consectectur adipisicing elit, Etisan vivera lorem ut enim interdum.'},
        {date: 'Aug 8, 2017', title: 'Lorem Ipsum', description: 'Lorem ipsum dolor sit amet consectectur adipisicing elit, Etisan vivera lorem ut enim interdum.'},
        {date: 'Aug 8, 2017', title: 'Lorem Ipsum', description: 'Lorem ipsum dolor sit amet consectectur adipisicing elit, Etisan vivera lorem ut enim interdum.'}
      ]
    }
  },
  components: {
    FooterApps
  },
  created () {
    this.getRecentNews()
  },
  computed: {
    ...mapGetters(['recentNews'])
  },
  methods: {
    getRecentNews () {
      this.$store.dispatch('getRecentNews', {
        success: () => {},
        fail: () => {}
      })
    },
    goto: function (url) {
      if (url !== '') {
        this.$router.push(url)
      }
    },
    viewDetail (content) {
      location.href = content
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-container {

}
</style>
