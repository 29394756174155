<template>
  <div>
    <v-breadcrumbs divider="-">
      <v-breadcrumbs-item @click="gotoCart">
        Cart
      </v-breadcrumbs-item>
      <v-breadcrumbs-item>
        Checkout (Shipment)
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card>
      <v-card-title class="remove-padding">
        <v-container gridl-list-md class="remove-botton-padding">
          <v-layout wrap align-center>
            <v-flex xs12 md6>
              <img src="/static/img/logo-sd-175.png" alt="Kopi Ketjil Logo" height="64" class="left">
            </v-flex>
            <v-flex xs12 md6 class="text-md-right pl-3">
              <span class="headline text-helvetica-xxs uppercase">Checkout</span>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 class="border-bottom">
              <h4 class="text-executive-xs uppercase">Ship to</h4>
            </v-flex>
            <v-flex xs12>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md12>
                    <h4 class="text-executive-xs uppercase">Shipping Address</h4>
                    <v-container grid-list-md>
                      <v-layout wrap v-model="valid">
                        <v-flex xs6 class="text-courier-new-xs">
                          <v-text-field label="First Name" required hide-details disabled v-model="currentUserInformation.data.first_name"></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="text-courier-new-xs">
                          <v-text-field label="Last Name" required hide-details disabled v-model="currentUserInformation.data.last_name"></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="text-courier-new-xs">
                          <v-text-field label="Street address and number" required hide-details v-model="param.address" v-validate="'required|min:10'" data-vv-name="address" :error-messages="errors.collect('address')"></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="text-courier-new-xs">
                          <v-text-field label="Zip Code / Postal Code" required hide-details v-model="param.zip_code" v-validate="'required'" data-vv-name="zip_code" :error-messages="errors.collect('zip_code')"></v-text-field>
                        </v-flex>
                        <v-flex xs12 class="text-courier-new-xs">
                          <v-select
                            v-model="param.country"
                            disabled="true"
                            :items="['Indonesia', 'United Arab Emirates', 'Others']"
                            label="Country"
                            v-validate="'required'" data-vv-name="country"
                            :error-messages="errors.collect('country')"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 class="text-courier-new-xs">
                          <v-text-field label="Phone Number" required hide-details prepend-icon="phone" v-model="param.phone_number" v-validate="'required'" data-vv-name="phone_number" :error-messages="errors.collect('phone_number')"></v-text-field>
                        </v-flex>
                        <v-flex xs12 class="text-courier-new-xs">
                          <v-select v-if="param.country == 'Indonesia' || param.country === 'Others'"
                            v-model="param.shipment"
                            :items="['POS Indonesia']"
                            label="Logistic Provider"
                            v-validate="'required'" data-vv-name="shipment"
                            :error-messages="errors.collect('shipment')"
                          ></v-select>
                          <v-select v-else-if="param.country == 'United Arab Emirates'"
                            v-model="param.shipment"
                            :items="['FEDEX', 'Emirates POS']"
                            label="Logistic Provider"
                            v-validate="'required'" data-vv-name="shipment"
                            :error-messages="errors.collect('shipment')"
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>

          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <small>*indicates required field</small>
        <v-btn light @click="gotoCart">Back to Cart</v-btn>
        <v-btn dark @click="gotoReview">Next to Review</v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      valid: false,
      rules: [
        v => !!v || 'Name is required'
      ],
      param: {},
      region: [],
    }
  },
  name: 'cart-checkout-first-step',
  created () {
    if (localStorage.region === '' || localStorage.region === null || localStorage.region === 'null') console.log('asd')
    else this.param.country = localStorage.region
    // this.param.country = localStorage.region
  },
  computed: {
    ...mapGetters(['currentUserInformation'])
  },
  beforeCreate () {
    this.$store.dispatch('getUser', {
      data: {},
      success: () => {},
      fail: () => {}
    })
  },
  methods: {
    gotoCart () {
      this.saveShipmentInformation()
      this.$router.push('/cart/')
    },
    gotoShipment () {
      this.saveShipmentInformation()
      this.$router.push('/cart/checkout/shipment')
    },
    gotoReview () {
      this.$validator.validateAll()
      if (this.valid) {
        this.saveShipmentInformation()
        this.$router.push('/cart/checkout/review')
      }
    },
    saveShipmentInformation () {
      this.$store.dispatch('setShipmentInformation', {
        data: Object.assign({}, this.param),
        success: () => {},
        fail: () => {}
      })
    }
  }
}
</script>
