<template>
  <v-app light>
    <AppHeader :navItems="navItems"/>
    <router-view></router-view>
    <AppFooter :navShortcuts="navShortcuts"/>
  </v-app>
</template>

<script>
import nav from '../_nav'
import { Header as AppHeader, Footer as AppFooter } from '../components/'

export default {
  name: 'full',
  components: {
    AppHeader,
    AppFooter
  },
  data () {
    return {
      navItems: nav.items,
      navShortcuts: nav.shortcuts,
      title: 'Coffee Ketjil'
    }
  },
  methods: {
  }
}
</script>
