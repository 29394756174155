<template>
  <div>
    <div :key="gallery.id" v-for="gallery in galleries.data">
      <section><!--drink-->
        <v-parallax :src="getBackgroundImage(gallery)" height="300">
          <v-layout column align-center justify-center class="white--text mr-5">
            <div class="white--text mb-3 text-md-center">
              <h1 class="text-executive-lg uppercase">Our {{ gallery.name }}s</h1>
            </div>
          </v-layout>
        </v-parallax>
      </section><!--/drink-->
      <section>
        <v-flex xs8 offset-xs2>
          <v-container grid-list-xl class="white--text text-lg-center">
            <v-layout row wrap align-center justify-center>
              <v-container grid-list-xl>
                <v-layout row wrap align-center>
                  <v-flex xs12 sm6 md3 :key="data.id" v-for="data in gallery.galleries">
                    <figure class="effect-gd pointer">
                      <img :src="getPath(data.image_url)" alt="Kopi Ketjil">
                      <figcaption>
                        <h4 class="effect1 text-executive-md">{{ data.name }}</h4>
                      </figcaption>
                    </figure>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-layout>
          </v-container>
        </v-flex>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config  from '../config'

export default {
  name: 'gallery-menu',
  created () {
    this.getGalleries()
  },
  computed: {
    ...mapGetters(['galleries'])
  },
  methods: {
    getGalleries () {
      this.$store.dispatch('getGalleries', {
        success: () => {
        },
        fail: () => {
        }
      })
    },
    getPath (fileName) {
      return config.storage_base_path + fileName
    },
    getBackgroundImage (gallery) {
      if (gallery.name.toLowerCase() === 'drink') {
        return '/static/img/background-drink.png'
      } else if (gallery.name.toLowerCase() === 'food') {
        return '/static/img/background-food.png'
      }
      return '/static/img/background-drink.png'
    }
  }
}
</script>
