<template>
  <div>
    <table class="gd-table">
      <thead>
      <tr class="text-courier-new-md-black uppercase gd-head">
        <th>Items Description</th>
        <th>Quantity</th>
        <th>Subtotal</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="cart.products.length === 0">
        <td colspan="3" class="text-xs-center text-md-center gd-table-information">
          There is no product(s) in your cart, start shopping!
        </td>
      </tr>
      <tr v-else-if="cart.products.length > 0" :key="product.id" v-for="product in cart.products" class="gd-product-list">
        <td>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap>
                <v-flex xs12 md3 v-if="product.product_images && product.product_images.length > 0">
                  <img :src="getPath(product.product_images[0].image_url)" alt="Kopi Ketjil" width="80">
                </v-flex>
                <v-flex xs12 md9>
                  <h4 class="text-executive-xs uppercase">
                    {{ product.name }}
                    <v-icon @click="remove(product)" class="ml-3 pointer">
                      delete
                    </v-icon>
                  </h4>
                  <p class="text-courier-new-xs-black hidden-xs-only" v-if="product.pivot.region === 'Indonesia'">Rp. {{ testing(product.indonesia_price, product.pivot.addonprice) }}</p>
                  <p class="text-courier-new-xs-black hidden-xs-only" v-if="product.pivot.region === 'United Arab Emirates'">AED {{ testing(product.aed_price, product.pivot.addonprice) }}</p>
                  <p class="text-courier-new-xs-black hidden-xs-only" v-if="product.pivot.region === 'Others'">$ {{ testing(product.usd_price, product.pivot.addonprice) }}</p>
                  <small class="text-courier-new-xxxs-black hidden-xs-only">{{ product.description }}</small><br>
                  <v-flex v-if="product.pivot.notes != ''">
                    <v-icon color= "green">info</v-icon>
                    <small class="text-courier-new-xxxs-black"><b>{{ product.pivot.notes }}</b></small><br>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </td>
        <td class="text-md-center text-xs-center">
          <v-text-field
            v-model="product.pivot.quantity"
            label="Quantity"
            type="number"
            required
            min="1"
            max="99"
            @change="changeQuantity(product)"
            hide-details></v-text-field>
        </td>
        <td class="text-md-right text-xs-right">
          <p v-if="product.pivot.region === 'Indonesia'">Rp. {{ product.subTotal }}</p>
          <p v-if="product.pivot.region === 'United Arab Emirates'">AED {{ product.subTotal }}</p>
          <p v-if="product.pivot.region === 'Others'">$ {{ product.subTotal }}</p>

        </td>
      </tr>
      <tr v-if="cart.products.length > 0" class="need-padding">
        <td class="uppercase" colspan="3">
          <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3" v-if="cart.products[0].pivot.region === 'Indonesia'">
            Total Price <p class="right">Rp. {{ cart.totalPrice }}</p> <br>
          </div>
          <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3" v-else-if="cart.products[0].pivot.region === 'United Arab Emirates'">
            Total Price <p class="right">AED {{ cart.totalPrice }}</p> <br>
          </div>
          <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3" v-else-if="cart.products[0].pivot.region === 'Others'">
            Total Price <p class="right">$ {{ cart.totalPrice }}</p> <br>
          </div>
          <p class="text-courier-new-xxxs">
            By proceeding to checkout, you agree to kopiketjil.com Terms and Condition
          </p>
        </td>
      </tr>
      <tr v-if="cart.products.length > 0">
        <td></td>
        <td colspan="2">
          <div class="right">
            <v-btn light @click="goto('/shops/')">Continue to Shopping</v-btn>
            <v-btn dark @click="goto('/cart/checkout')">Proceed to Checkout</v-btn>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from "../config";

export default {
  data () {
    return {
      totalProduct: 0
    }
  },
  name: 'cart-detail',
  computed: {
    ...mapGetters(['cart'])
  },
  created () {
    this.loadCartData()
    this.prepareCalculation()
  },
  methods: {
    loadCartData () {
      this.$store.dispatch('getCartData', {
        success: (res) => {
          this.totalProduct = res.data.data.products.length
          console.log(this.totalProduct)
          if (this.totalProduct === 0 || this.totalProduct === '0') {
            console.log('sudah kosong')
            localStorage.region = ''
          }
        },
        fail: () => {}
      })
    },
    testing (price, addon) {
      if (addon !== null || addon !== 0 || addon !== '') return parseInt(price) + parseInt(addon)
      return parseInt(price)
    },
    prepareCalculation () {
      let totalPrice = 0
      for (let i = 0; i < this.cart.products.length; i++) {
        let product = this.cart.products[i]
        let productPrice = 0
        if (product.pivot.region === 'Indonesia') productPrice = product.indonesia_price
        else if (product.pivot.region === 'United Arab Emirates') productPrice = product.aed_price
        else if (product.pivot.region === 'Others') productPrice = product.usd_price
        product.subTotal = product.pivot.quantity * (productPrice + product.pivot.addonprice)
        totalPrice += product.subTotal
      }
      this.cart.totalPrice = totalPrice
    },
    getPath (fileName) {
      return config.storage_base_path + fileName
    },
    changeQuantity (product) {
      let param = {
        id: product.id,
        quantity: product.pivot.quantity
      }
      this.$store.dispatch('updateCartProductQuantity', {
        data: Object.assign({}, param),
        success: () => {},
        fail: () => {}
      })
    },
    remove (product) {
      this.cart.products.splice(this.cart.products.indexOf(product), 1)
      this.$store.dispatch('removeCartProduct', {
        data: Object.assign({}, product),
        success: () => {
          this.$toast('Cart product has been successfully removed')
          this.loadCartData()
        },
        fail: () => {
          this.$toast('Something went wrong when try to deleting product')
        }
      })
    },
    goto (path) {
      this.$router.push(path)
    }
  },
  watch: {
    cart: {
      handler (val) {
        let totalPrice = 0
        if (val) {
          for (let i = 0; i < val.products.length; i++) {
            let product = val.products[i]
            let productPrice = 0
            if (product.pivot.region === 'Indonesia') productPrice = product.indonesia_price
            else if (product.pivot.region === 'United Arab Emirates') productPrice = product.aed_price
            else if (product.pivot.region === 'Others') productPrice = product.usd_price
            product.subTotal = product.pivot.quantity * (productPrice + product.pivot.addonprice)
            totalPrice += product.subTotal
          }
          val.totalPrice = totalPrice
        }
      },
      deep: true
    }
  }
}
</script>
