import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource';
import Vuetify from "vuetify";
import VueMoment from 'vue-moment';
import * as VeeValidate from 'vee-validate';
import Toast from "vue2-toast/src";
import router from './router'
import store from './store'
import 'vue2-toast/lib/toast.css'
import 'vuetify/dist/vuetify.min.css'
import 'font-awesome/css/font-awesome.css'

/* eslint-disable */
Vue.config.productionTip = false
Vue.use(VueResource);
Vue.use(Vuetify);
Vue.use(VueMoment);
Vue.use(VeeValidate);
Vue.use(Toast);

new Vue({
  Vuetify,
  el: '#app',
  router,
  store,
  render: h => h(App)
})

