<template>
    <div>
        <v-breadcrumbs divider="-">
            <v-breadcrumbs-item @click="gotoCart">
                Cart
            </v-breadcrumbs-item>
            <v-breadcrumbs-item @click="gotoForm">
                Checkout (Shipment)
            </v-breadcrumbs-item>
            <v-breadcrumbs-item>
                Checkout (Review)
            </v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-card class="mb-0">
            <v-card-title>
                <h3 class="headline">Transaction Review</h3>
            </v-card-title>
            <table class="gd-table mb-0">
                <thead>
                <tr class="text-courier-new-md-black uppercase gd-head">
                    <th>Items Description</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="cart.products.length === 0">
                    <td colspan="3" class="text-xs-center text-md-center gd-table-information">
                        There is no product(s) in your cart, start shopping!
                    </td>
                </tr>
                <tr v-else-if="cart.products.length > 0" :key="index" v-for="(product, index) in cart.products"
                    class="gd-product-list">
                    <td>
                        <v-flex xs12>
                            <v-container grid-list-xl>
                                <v-layout row wrap>
                                    <v-flex xs12 md3 v-if="product.product_images && product.product_images.length > 0">
                                        <img :src="getPath(product.product_images[0].image_url)" alt="Kopi Ketjil"
                                             width="80">
                                    </v-flex>
                                    <v-flex xs12 md9>
                                        <h4 class="text-executive-xs uppercase">
                                            {{ product.name }}
                                            <v-icon color="red" @click="remove(product, index)" class="pointer">
                                                close
                                            </v-icon>
                                        </h4>
                                        <p class="text-courier-new-xs-black" v-if="product.pivot.region === 'Others'">$
                                            {{ parseInt(product.usd_price) + parseInt(product.pivot.addonprice) }}</p>
                                        <p class="text-courier-new-xs-black"
                                           v-else-if="product.pivot.region === 'United Arab Emirates'">AED {{
                                            parseInt(product.aed_price) + parseInt(product.pivot.addonprice) }}</p>
                                        <p class="text-courier-new-xs-black"
                                           v-if="product.pivot.region === 'Indonesia'">Rp. {{
                                            parseInt(product.indonesia_price) + parseInt(product.pivot.addonprice)
                                            }}</p>
                                        <small class="text-courier-new-xxxs-black">{{ product.description }}</small>
                                        <v-flex v-if="product.pivot.notes != ''">
                                            <v-icon color="green">info</v-icon>
                                            <small class="text-courier-new-xxxs-black"><b>{{ product.pivot.notes }}</b></small><br>
                                        </v-flex>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-flex>
                    </td>
                    <td class="text-md-center text-xs-center">
                        {{ product.pivot.quantity}} pcs
                    </td>
                    <td class="text-md-right text-xs-right">
                        <p v-if="product.pivot.region === 'Others'">$ {{ product.subTotal }}</p>
                        <p v-if="product.pivot.region === 'Indonesia'">Rp. {{ product.subTotal }}</p>
                        <p v-if="product.pivot.region === 'United Arab Emirates'">AED {{ product.subTotal }}</p>
                    </td>
                </tr>
                <tr v-if="cart.products.length > 0" class="need-padding">
                    <td></td>
                    <td class="uppercase" colspan="2">
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'Others'">
                            Subtotal Price <p class="right">$ {{ cart.totalPrice }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'Indonesia'">
                            Subtotal Price <p class="right">Rp. {{ cart.totalPrice }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'United Arab Emirates'">
                            Subtotal Price <p class="right">AED {{ cart.totalPrice }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'Others'">
                            Shipping Price <p class="right">$ {{ shipping.shipping_price }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'Indonesia'">
                            Shipping Price <p class="right">Rp. {{ shipping.shipping_price }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'United Arab Emirates'">
                            Shipping Price <p class="right">AED {{ shipping.shipping_price }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'Others'">
                            Total Price <p class="right">$ {{ cart.totalPrice + shipping.shipping_price }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'Indonesia'">
                            Total Price <p class="right">Rp. {{ cart.totalPrice + shipping.shipping_price }}</p> <br>
                        </div>
                        <div class="text-courier-new-md-black bold border-bottom mb-3 pb-3"
                             v-if="cart.products[0].pivot.region === 'United Arab Emirates'">
                            Total Price <p class="right">AED {{ cart.totalPrice + shipping.shipping_price }}</p> <br>
                        </div>
                        <p class="text-courier-new-xxxs">
                            By proceeding to checkout, you agree to kopiketjil.com Terms and Condition.
                        </p>
                        <!--            <p class="text-courier-new-xxxs"><b>*The shipping charge calculation will be emailed later after you checkout.</b></p>-->
                    </td>
                </tr>
                </tbody>
            </table>
            <v-card-text>
                <v-layout wrap>
                    <v-container>
                        <v-flex xs12 class="border-bottom">
                            <h3 class="headline">Payment</h3>
                        </v-flex>
                    </v-container>
                    <v-flex xs12>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 md12>
                                    <h4 class="text-executive-xs uppercase">Credit Card</h4>
                                    <v-container grid-list-md>
<!--                                        <img v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/visa.png'" class="v-parallax__image-container">-->
                                        <v-layout wrap>
                                            <v-flex xs12 class="text-courier-new-xs">
                                                <v-text-field label="Card Number" @change="changeCardNumber" @input="changeCardNumber" max="19" v-model="payment.cardNumber" required
                                                              hide-details></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 class="text-courier-new-xs">
                                                <v-text-field label="Card Holder" v-model="payment.cardHolder" required
                                                              hide-details></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 class="text-courier-new-xs">
                                                <b>Expired Date</b>
                                            </v-flex>
                                            <v-flex xs4 class="text-courier-new-xs">
                                                <v-select
                                                        v-model="payment.cardExpiredDate"
                                                        :items="cardMonth"
                                                        label="Month"
                                                        v-validate="'required'" data-vv-name="country"
                                                ></v-select>
                                            </v-flex>
                                            <v-flex xs4 class="text-courier-new-xs">
                                                <v-select
                                                        v-model="payment.cardExpiredYear"
                                                        :items="cardYears"
                                                        label="Year"
                                                        v-validate="'required'" data-vv-name="country"
                                                ></v-select>
                                            </v-flex>
                                            <v-flex xs4 class="text-courier-new-xs">
                                                <v-text-field type="number" label="CVV" v-model="payment.cardCVV"
                                                              required hide-details></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-flex>
                </v-layout>
                <v-card dark>
                    <v-card-text>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex sm12>
                                    <b>
                                        To: {{ currentUserInformation.data.first_name }} {{
                                        currentUserInformation.data.last_name }} <br>
                                    </b>
                                    Address: {{ shipmentInformation.address }}, {{ shipmentInformation.zip_code }} ( {{
                                    shipmentInformation.country}} )<br>
                                    <v-icon small>phone</v-icon>
                                    {{ shipmentInformation.phone_number }} <br>
                                    <v-icon small>airport_shuttle</v-icon>
                                    {{ shipmentInformation.shipment }}
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions v-if="cart.products.length > 0">
                <v-spacer></v-spacer>
                <div class="right">
                    <v-btn light @click="gotoForm">Back to Form</v-btn>
                    <v-btn dark @click="confirmTransaction" :disabled="isSubmitting == 1">Confirm and Send</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import config from "../config";

    export default {
        name: 'cart-checkout-second-step',
        data() {
            return {
                cardMonth: [],
                cardYears: [],
                payment: {
                    cardNumber: null,
                    cardHolder: null,
                    cardExpiredDate: null,
                    cardExpiredYear: null,
                    cardCVV: null,
                },
                tokenCard: null,
                shipping: {
                    shipping_price: 0,
                    currency: "AED",
                    details: {
                        origin: "UAE",
                        destination: "Indonesia",
                        price_per_kg: 0,
                        price_currency: "AED",
                        total_weight: 0
                    }
                },
                isSubmitting: 0
            }
        },
        created() {

            this.loadCartData()
            this.loadShippingCalculate()
            this.prepareCalculation()
            if (Object.keys(this.shipmentInformation).length === 0) {
                this.gotoForm()
            }

            let year = new Date().getFullYear() - 1;
            let month = 0;

            for (let i = 1; i < 11; i++) {
                this.cardYears.push(i + year)
            }

            for (let i = 1; i < 13; i++) {
                this.cardMonth.push((i + month) < 10 ? "0" + (i + month) : i + month)
            }
        },
        computed: {
            ...mapGetters(['currentUserInformation', 'cart', 'shipmentInformation'])
        },
        methods: {
            changeCardNumber(value){
                console.log(value);
                this.payment.cardNumber = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');

            },
            remove(cart, index) {
                this.$store.state.cart.products.splice(index, 1);
                this.cart.products.splice(index, 1);
                this.$store.dispatch('removeCartProduct', {
                    data: cart,
                    success: (res) => {
                        this.shipping = res.data.data
                    },
                    fail: () => {
                    }
                })
            },
            loadShippingCalculate() {
                let origin = "ID";

                if (this.$store.state.shipmentInformation.country === "Indonesia") {
                    origin = "ID"
                } else if (this.$store.state.shipmentInformation.country === "United Arab Emirates") {
                    origin = "UAE"
                } else {
                    origin = "US"
                }

                let quantity = 0;
                this.cart.products.map(function (product, index) {
                    quantity += product.pivot.quantity * product.weight
                })

                this.$store.dispatch('getCalculateShipping', {
                    data: {
                        "origin": origin,
                        "destination": this.$store.state.shipmentInformation.country,
                        "weight": quantity
                    },
                    success: (res) => {
                        this.shipping = res.data.data
                    },
                    fail: () => {
                    }
                })
            },
            isDisabled() {
                return this.form.disable
            },
            gotoCart() {
                this.$router.push('/cart/')
            },
            gotoForm() {
                this.$router.push('/cart/checkout/shipment')
            },
            confirmTransaction() {
                this.$store.dispatch('createToken', {
                    data: JSON.stringify({
                        is_single_use: true,
                        card_data: {
                            account_number: this.payment.cardNumber.replace(/ /g, ""),
                            exp_month: this.payment.cardExpiredDate.toString(),
                            exp_year: this.payment.cardExpiredYear.toString(),
                            cvn: this.payment.cardCVV.toString()
                        },
                        should_authenticate: false,
                        amount: this.cart.totalPrice + this.shipping.shipping_price,
                        card_cvn: this.payment.cardCVV
                    }),
                    success: (res) => {
                        console.log(res);
                        if (res.data.status === "VERIFIED") {
                            this.tokenCard = res.data.id;
                            let param = this.shipmentInformation
                            param.region = localStorage.region
                            param.cc_cvn = this.payment.cardCVV.toString();
                            param.currency = this.shipping.details.price_currency;
                            param.shipping_amount = this.shipping.shipping_price;
                            param.cc_token_id = res.data.id;
                            this.isSubmitting = 1
                            this.$store.dispatch('checkoutTransaction', {
                                data: Object.assign({}, param),
                                beforeSend: () => {
                                    this.isDisabled()
                                },
                                success: (res) => {
                                    this.$router.push('/transaction/history')
                                    this.$toast('Success', res)
                                    this.isSubmitting = 0
                                },
                                fail: (err) => {
                                    console.log(err)
                                    this.$toast('Error on saving transaction', err)
                                    this.isSubmitting = 0
                                }
                            })
                        } else {
                            this.$toast("Credit card not valid");
                        }
                    },
                    fail: (err) => {
                        this.$store.state.loading = false;
                        this.$toast(err.response.data.errors[0].message);
                    }
                })
            },
            loadCartData() {
                this.$store.dispatch('getCartData', {
                    success: () => {
                    },
                    fail: () => {
                    }
                })
            },
            prepareCalculation() {
                let totalPrice = 0
                for (let i = 0; i < this.cart.products.length; i++) {
                    let product = this.cart.products[i]
                    let productPrice = 0
                    if (product.pivot.region === 'Indonesia') productPrice = product.indonesia_price
                    else if (product.pivot.region === 'United Arab Emirates') productPrice = product.aed_price
                    else if (product.pivot.region === 'Others') productPrice = product.usd_price
                    product.subTotal = product.pivot.quantity * (productPrice + product.pivot.addonprice)
                    totalPrice += product.subTotal
                }
                this.cart.totalPrice = totalPrice
            },
            getPath(fileName) {
                return config.storage_base_path + fileName
            }
        },
        watch: {
            cart: {
                handler(val) {
                    let totalPrice = 0
                    if (val) {
                        for (let i = 0; i < val.products.length; i++) {
                            let product = val.products[i]
                            let productPrice = 0
                            if (product.pivot.region === 'Indonesia') productPrice = product.indonesia_price
                            else if (product.pivot.region === 'United Arab Emirates') productPrice = product.aed_price
                            else if (product.pivot.region === 'Others') productPrice = product.usd_price
                            product.subTotal = product.pivot.quantity * (productPrice + product.pivot.addonprice)
                            totalPrice += product.subTotal
                        }
                        val.totalPrice = totalPrice
                    }
                },
                deep: true
            }
        }
    }
</script>
