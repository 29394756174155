<template>
  <v-content class="mb-5 gd-background">
    <section><!--header wishlist-->
      <v-parallax src="/static/img/header-coffee.png" height="250" class="remove-padding">
        <v-layout column align-center justify-center>
          <div class="white--text text-md-center px-5">
            <h1 class="text-executive-lg">MY WISHLIST</h1>
            <p class="text-courier-new-xs-white px-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
          </div>
        </v-layout>
      </v-parallax>
    </section><!--/header wishlist-->

    <section class="mb-5"><!--grid wishlist-->
      <v-layout column wrap px-5>
        <v-flex xs12>
          <v-container grid-list-xl>
            <router-view></router-view>
          </v-container>
        </v-flex>
      </v-layout>
    </section><!--/grid wishlist-->
  </v-content>
</template>

<script>
export default {
  name: 'wishlist'
}
</script>
