<template>
  <v-content class="mb-5 gd-background">
    <section>
      <!--header shop-->
      <v-parallax src="/static/img/header-coffee.png" height="250" class="remove-padding">
        <v-layout column align-center justify-center>
          <div class="white--text text-md-center px-5">
            <h1 class="text-executive-lg">SHOP</h1>
            <!-- <p class="text-courier-new-xs-white px-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> -->
          </div>
        </v-layout>
      </v-parallax>
      <div class="shop-nav">
        <v-btn
          :key="category.id"
          v-for="category in categories"
          @click="filter(category)"
          flat
          class="text-md-center white--text text-executive-xs"
        >{{ category.name }}</v-btn>
      </div>
    </section>
    <!--/header shop-->

    <section class="mb-5">
      <!--grid shop-->
      <v-layout column wrap px-5>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap>
              <v-flex xs12 md3 class="text-courier-new-xs-black form-black">
                <!-- <v-form v-model="valid" ref="form" lazy-validation class="text-courier-new-xs-black form-black"> -->
                <div class="ma-3">
                  <v-text-field
                    append-icon="search"
                    name="search"
                    v-model="param.filter.search"
                    @change="getProducts()"
                    label="Search"
                  ></v-text-field>
                </div>
                <div class="ma-3" v-if="category != null">
                  <hr>
                  <template
                    v-for="childOne in category.children"
                    v-if="childOne.children.length > 0"
                  >
                    <h3 :key="childOne.id" class="mt-3 mb-3 uppercase">{{childOne.name}}</h3>
                    <v-checkbox
                      v-for="childSecond in childOne.children"
                      :key="childSecond.id"
                      :label="childSecond.name"
                      v-model="categoriesChecked"
                      :value="childSecond.id"
                      hide-details
                      @change="filterChild(category.id, childSecond.id)"
                    />
                  </template>
                  <template
                    v-for="childOne in category.children"
                    v-if="childOne.children.length == 0"
                  >
                    <v-checkbox
                      :key="childOne.id"
                      :label="childOne.name"
                      v-model="categoriesChecked"
                      :value="childOne.id"
                      hide-details
                      @change="filterChild(category.id, childOne.id)"
                    />
                  </template>
                  <!-- <v-checkbox label="In Stock" v-model="param.filter.stock" value="in stock" hide-details></v-checkbox>
                  <v-checkbox label="Out of Stock" v-model="param.filter.stock" value="out of stock" hide-details></v-checkbox>-->
                </div>
                <!-- </v-form> -->
              </v-flex>
              <v-flex xs12 md9>
                <v-container grid-list-xl>
                  <router-view></router-view>
                </v-container>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>
    <!--/grid shop-->
  </v-content>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'shop',
  created () {
    this.param.filter.categories = '115'
    this.getCategories()
    this.getProducts()
  },
  data () {
    return {
      param: {
        search: '',
        categories: '',
        filter: {
          categories: '',
          category_children: '',
          search: ''
        }
      },
      valid: null,
      category: {},
      products: {},
      categoriesChecked: []
    }
  },
  computed: {
    ...mapGetters(['categories'])
  },
  methods: {
    filter (category) {
      if (!this.category) {
        this.category = {}
      }
      this.category = Object.assign({}, category)
      this.param.filter.categories = ''
      this.param.filter.categories = category.id
      this.param.filter.category_children = ''
      this.getProducts()
    },
    filterChild (categoryId, id) {
      let varNames = ''
      for (let i = 0; i < this.categoriesChecked.length; i++) {
        if (i === 0) {
          varNames += this.categoriesChecked[i]
        } else {
          varNames += ',' + this.categoriesChecked[i]
        }
      }
      this.param.filter.category_children = varNames
      this.getProducts()
    },
    filterSearch () {
      this.getProducts()
    },
    getPath: function (fileName) {
      return '/static/img/products/' + fileName
    },
    getCategories () {
      this.$store.dispatch('getCategories', {
        success: () => {
          this.category = this.categories[0]
        },
        fail: () => {}
      })
    },
    getProducts () {
      this.$store.dispatch('getProducts', {
        success: () => {},
        fail: () => {},
        data: this.param.filter
      })
    },
    getNewProducts () {
      this.$store.dispatch('getProducts', {
        success: res => {
          console.log(res)
        },
        fail: () => {},
        data: this.param
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-nav {
  display: flex;
  background-color: #424242;
  justify-content: space-evenly;
}

@media (max-width: 576px) {
  .shop-nav {
    flex-direction: column;
  }
}
</style>
