<template>
  <div>
    <section><!--news feed-->
      <v-parallax src="/static/img/background-news-feed.png" height="300">
        <v-layout column align-center justify-center class="white--text mr-5">
          <div class="white--text mb-3 text-md-center">
            <h1 class="text-executive-lg uppercase">News Feed</h1>
          </div>
        </v-layout>
      </v-parallax>
    </section><!--/news feed-->

    <section><!--feed-->
      <v-layout row wrap px-5>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap>
              <v-flex xs12 md8>
                <v-card color="transparent"
                        class="white--text mb-3"
                        :key="newsFeed.id"
                        v-for="newsFeed in newsFeeds.data.data">
                  <v-container fluid grid-list-lg class="gd-border-solid">
                    <div class="gd-date-absolute text-executive-md text-md-center">
                      <div class="date-year">{{ newsFeed.created_at | moment("YY") }}</div>
                      <div class="date-day">{{ newsFeed.created_at | moment("MM") }}</div>
                    </div>
                    <v-layout row>
                      <v-flex xs3>
                        <v-card-media
                          :src="getPath(newsFeed.image_url)"
                          height="125px"
                          width="210px"
                          contain
                        ></v-card-media>
                      </v-flex>
                      <v-flex xs7>
                        <div>
                          <div class="headline mb-2 text-executive-lg-black bold">{{ newsFeed.title }}</div>
                          <div class="mb-2 text-courier-new-xs-black wordwrap">{{ newsFeed.content }}</div>
                          <span class="bold">
                            <a @click="viewDetail(newsFeed.content)">MORE INFO</a>
                          </span>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
                <v-pagination :length="newsFeeds.data.last_page"
                              v-model="newsFeeds.data.current_page"
                              @input="loadPage"></v-pagination>
              </v-flex>
              <v-flex xs12 md4>
                <div class="ma-3">
                  <h2 class="mt-3 mb-3 uppercase text-helvetica-lg">Recent Post</h2>
                  <hr>
                  <v-flex xs12 md12 :key="post.id" v-for="post in recentPosts.data">
                    <v-container grid-list-md>
                      <v-layout row>
                        <v-flex xs12 md4>
                          <img :src="getPath(post.image_url)" alt="Posts" height="96px">
                        </v-flex>
                        <v-flex xs12 md12>
                          <h3 class="text-courier-new-md-black bold">
                            {{ post.createdDate | moment("MMMM Do, YYYY") }}
                          </h3>
                          <p class="text-courier-new-xs-black wordwrap">
                            {{ post.content }}
                          </p>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-flex>
                  <!--<h2 class="mt-5 mb-3 uppercase text-helvetica-lg">Categories</h2>-->
                  <!--<hr>-->
                  <!--<h3 class="text-courier-new-md-black mb-2">Categories 1</h3>-->
                  <!--<h3 class="text-courier-new-md-black mb-2">Categories 1</h3>-->
                  <!--<h3 class="text-courier-new-md-black mb-2">Categories 1</h3>-->
                  <!--<h3 class="text-courier-new-md-black mb-2">Categories 1</h3>-->
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section><!--/feed-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from "../config";

export default {
  name: 'news-feed',
  data () {
    return {
      param: {}
    }
  },
  created () {
    this.getNewsFeed()
    this.getRecentPosts()
  },
  computed: {
    ...mapGetters(['newsFeeds', 'recentPosts'])
  },
  methods: {
    loadPage (page) {
      this.param.page = page
      this.getNewsFeed()
    },
    getNewsFeed () {
      this.$store.dispatch('getNewsFeed', {
        data: Object.assign({}, this.param),
        success: () => {},
        fail: () => {}
      })
    },
    getRecentPosts () {
      this.$store.dispatch('getRecentPosts', {
        success: () => {},
        fail: () => {}
      })
    },
    viewDetail: function (content) {
      // this.$router.push('/news/feed/' + id)
      location.href = content
    },
    getPath: function (fileName) {
      if (fileName === undefined) {
        return '/static/img/news/default-210.png'
      }
      return config.storage_base_path + fileName
    }
  }
}
</script>
