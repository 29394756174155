<template>
  <v-footer class="white darken-2 margin-top-minus-3 py-3 px-3" height="auto">
    <v-layout row wrap>
      <v-flex xs12 md8>
        <v-container>
          <v-layout row wrap>
            <v-flex xs6 md2 class="text-xs-left my-2" :key="index" v-for="(shortcut, index) in navShortcuts">
              <div class="mb-2">
                <h5 class="text-helvetica-xxs uppercase bold">{{ shortcut.name }}</h5>
              </div>
              <ul class="gd-list-container">
                <li :key="shortcutChild.name" v-for="shortcutChild in shortcut.childs">
                  <a :href="shortcutChild.url" class="text-courier-new-xxs">
                    {{ shortcutChild.name }}
                  </a>
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 md4>
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 class="text-xs-left">
              <div class="mb-2">
                <img src="/static/img/logo-sd-175.png" alt="Kopi Ketjil Logo" height="94">
                <h5 class="text-courier-new-xs-black-bold">Stay Update with Us</h5>
                <v-text-field
                  color="black darken-2"
                  name="input-1-3"
                  label="Email address"
                  single-line
                  prepend-icon="mail"
                  append-icon="send"
                ></v-text-field>
              </div>
              <div class="gd-social-media-container black--coffee">
                <a href="https://www.facebook.com/kopiketjil.uae">
                  <img src="/static/img/social_media/Facebook.jpg" alt="Kopi Ketjil - Facebook" height="40">
                </a>
                <a href="https://www.instagram.com/kopiketjil.uae/">
                  <img src="/static/img/social_media/IG.jpg" alt="Kopi Ketjil - Instagram" height="40">
                </a>
                <a href="https://www.youtube.com/channel/UCYZKzx1w839j5iaa5XHWXUQ">
                  <img src="/static/img/social_media/Youtube.jpg" alt="Kopi Ketjil - Youtube" height="40">
                </a>
                <a href="#">
                  <img src="/static/img/social_media/Snapchat.jpg" alt="Kopi Ketjil - Snapchat" height="40">
                </a>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: 'app-footer',
  props: {
    navShortcuts: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>
