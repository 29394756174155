export default {
  items: [
    {
      name: 'About Us',
      url: '',
      icon: 'info',
      childs: [
        {
          name: 'Our Background & Vision',
          url: '/about-us/background',
          icon: 'assignment'
        },
        // {
        //   name: 'Our Store',
        //   url: '/about-us/store',
        //   icon: 'home'
        // },
        {
          name: 'Franchising',
          url: '/franchise',
          icon: 'poll'
        }
        // ,
        // {
        //   name: 'Contact Us',
        //   url: '/contact-us',
        //   icon: 'contacts'
        // }
      ]
    },
    {
      name: 'What\'s New',
      url: '',
      icon: 'grade',
      childs: [
        {
          name: 'Hall',
          url: '/news/hall',
          icon: 'public'
        },
        {
          name: 'Promotion',
          url: '/news/promotion',
          icon: 'card_membership'
        },
        {
          name: 'News',
          url: '/news/feed',
          icon: 'grade'
        }
      ]
    },
    {
      name: 'Gallery',
      url: '',
      icon: 'monochrome_photos',
      childs: [
        {
          name: 'Menu',
          url: '/gallery/menu',
          icon: 'menu'
        },
        {
          name: 'Video',
          url: '/gallery/video',
          icon: 'video_library'
        }
      ]
    },
    {
      name: 'Shops',
      url: '/shops',
      icon: 'shopping_cart'
    },
    // {
    //   name: 'Catering',
    //   url: '/catering',
    //   icon: 'shopping_basket'
    // },
    {
      name: 'Apps',
      url: '/apps',
      icon: 'apps'
    }
  ],
  shortcuts: [
    {
      name: 'About Us',
      childs: [
        {name: 'Our Story', url: '#'},
        {name: 'Our Vision', url: '#'},
        {name: 'Franchising', url: '#'},
        {name: 'Store Locator', url: '#'},
        {name: 'Our Contact', url: '#'}
      ]
    },
    {
      name: 'What\'s New',
      childs: [
        {name: 'Promotions', url: '#'},
        {name: 'News', url: '#'}
      ]
    },
    {
      name: 'Gallery',
      childs: [
        {name: 'Our Menu', url: '#'},
        {name: 'Video', url: '#'}
      ]
    },
    {
      name: 'Shops',
      childs: [
        {name: 'Bean', url: '#'},
        {name: 'Equipment', url: '#'},
        {name: 'Merchandise', url: '#'},
        {name: 'Coffee Gift', url: '#'}
      ]
    },
    {
      name: 'Press',
      childs: [
        {name: 'Instagram', url: '#'},
        {name: 'Facebook', url: '#'}
      ]
    }
  ]
}
